import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Layout from 'components/system/layouts/Layout';
import redirectLinks from 'constants/redirectLinks';
import DecisionButton from 'components/machineConf/atoms/DecisionButton';
import { Box, Grid, makeStyles } from '@material-ui/core';
import ScreenContext from 'Contexts/Common/ScreenContext';
import { useAppDispatch } from 'redux/hooks';
import COMMON from 'constants/common';
import MESSAGES from 'constants/messages';
import { getUserAuthThunk } from 'redux/slices/commonSlice';
import { ConfirmDialog, InfoDialog, NormalSelectBox } from 'components/system/organisms';
import PrevNextButton from 'components/system/atoms/buttons/PrevNextButton';
import { animateScroll as scroll } from 'react-scroll';
import { getClsfcnByCategory } from 'utils/common.helper';
import CLSFCN from 'constants/classification';
import HttpConnection from 'utils/httpConnection';
import { REST_API } from 'constants/apiUrls';
import ReturnButton from 'components/system/atoms/buttons/ReturnButton';
import VoiceStationTable from 'components/machineConf/organisms/tables/VoiceStationTable';
import {
  MachineItemSubmitType,
  MachineListType,
  VoiceCancelItemType,
  VoiceLicenseCount,
  VoiceLocationType,
} from 'types/voice/voiceType';
import ConfirmDialogWithTitle from 'components/system/organisms/dialogs/ConfirmDialogWithTitle';
import InfoDialogWithTitle from '../../components/system/organisms/dialogs/InfoDialogWithTitle';

/* ************ Context ************ */
const ScreenContextValue = {
  title: '対象機器選択',
  screenId: 'SCR812',
  currentMenu: COMMON.MENU.VOICE_LIST,
  initializeState: true,
};
const TABLE_ID = 'dataStationTable';
const MAX_DISPLAY_BAR = 7;

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  contents: {
    padding: '12px 16px 24px 16px',
  },
  topArea: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '18px',
  },
  buttonReturnDescriptionContent: {
    display: 'flex',
  },
  selectBoxWrap: { marginRight: '12px' },
  description: {
    padding: '0 0 0px 24px',
    height: '36px',
    fontSize: '19px',
    whiteSpace: 'nowrap',
  },
  decision: {
    textAlign: 'right',
    width: '1100px',
    marginTop: '-20px',
  },
  previousBtn: {
    paddingBottom: '10px',
    position: 'inherit',
    textAlign: 'center',
    width: '100%',
    height: '29px',
    marginTop: '10px',
  },
  headerH1Container: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 1100,
    position: 'absolute',
    marginTop: 7,
  },
  nextBtn: {
    paddingTop: '5px',
    textAlign: 'center',
    height: '29px',
  },
  selectBoxOrder: {
    marginTop: '10px',
  },
  headerH1: {
    fontSize: 19,
  },
  contentConfigDialogText: {
    color: '#F5EF43',
    paddingLeft: 38,
  },
}));

/* ******** Main Component ******** */
const VoiceStationSelect: React.FC = () => {
  /* ************ hooks ************ */
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation<VoiceLocationType>();
  const voiceId = location.state?.voiceId;
  const voiceName = location.state?.voiceName;
  /* ************ state/redux ************ */
  const [machineList, setMachineList] = React.useState<MachineListType[]>([]);
  const [machineListOriginal, setMachineListOriginal] = React.useState<MachineListType[]>([]);
  const [listSort, setListSort] = React.useState<string>(CLSFCN.DATA_STATION_SORT.STATION_NAME_ASC);
  const [machineTypeFilter, setMachineTypeFilter] = React.useState<string>('99');
  const [countAuthMachine, setCountAuthMachine] = React.useState<number>(0);
  const [countLicenseLimit, setCountLicenseLimit] = React.useState<number>(0);
  const dispatch = useAppDispatch();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [cancelVoiceConfirmDialog, setCancelVoiceConfirmDialog] = useState(false);
  const [voiceCancelRowSelected, setVoiceCancelRowSelected] = useState<VoiceCancelItemType>({
    voiceId: 0,
    boardSerial: '',
    stationId: '',
  });
  const [openDialogINF254, setOpenDialogINF254] = useState(false);
  const [openDialogINF255, setOpenDialogINF255] = useState(false);
  const [openDialogINF539, setOpenDialogINF539] = useState(false);
  const dataSourceFilter = getClsfcnByCategory(CLSFCN.VOICE_MACHINE_TYPE_FILTER.CATEGORY_CD);
  const dataSourceSort = getClsfcnByCategory(CLSFCN.DATA_STATION_SORT.CATEGORY_CD);
  const [machineListSubmit, setMachineListSubmit] = React.useState<MachineItemSubmitType[]>([]);
  const [isSubmitReady, setIsSubmitReady] = React.useState(false);
  const [isShowNextButton, setIsShowNextButton] = useState(false);

  /* ************ Event ************ */
  useEffect(() => {
    dispatch(getUserAuthThunk());
    handleGetMachineListData().then();
  }, [listSort]);

  useEffect(() => {
    if (machineListOriginal.length > 0) {
      let newMachineList: MachineListType[];
      if (machineTypeFilter === '99') {
        newMachineList = machineListOriginal;
      } else {
        const machineTypeSelected = machineTypeFilter === '0' ? null : machineTypeFilter;
        newMachineList = machineListOriginal.filter((item) => item.machineType === machineTypeSelected);
      }
      setMachineList(newMachineList);
    }
  }, [machineTypeFilter, machineListOriginal]);

  const handleGetMachineListData = useCallback(async () => {
    const http = new HttpConnection({ dispatch });
    const data: MachineListType[] = await http.get<MachineListType[]>(REST_API.VOICE.GET_MACHINE_LIST, {
      voiceId,
      sort: listSort,
    });
    if (isSubmitReady) {
      setIsSubmitReady(false);
    }
    // set count
    if (data.length > 0) {
      const machineDataListFormat = data.map((item) => {
        // Map with old original machine list
        if (machineListOriginal.length) {
          const oldValue = machineListOriginal.find((itemOld) => itemOld.boardSerial === item.boardSerial);
          if (oldValue) {
            // Check disable
            const isDisable =
              (item.voiceId !== voiceId &&
                item.voiceId !== null &&
                (item.voiceReceive === '1' || item.voiceReceive === '2')) ||
              (item.voiceId === voiceId && (item.voiceReceive === '1' || item.voiceReceive === null));
            // Check isSelected
            let currentIsSelected = false;
            if (item.voiceId === voiceId && item.voiceReceive === '2') {
              currentIsSelected = true;
            }
            return {
              ...item,
              isSelected: isSubmitReady ? currentIsSelected : oldValue.isSelected,
              checked: isDisable ? false : oldValue.checked,
            };
          }
        }
        if (item.voiceId === voiceId && item.voiceReceive === '2') {
          return { ...item, isSelected: true, checked: true };
        }
        return { ...item, isSelected: false, checked: false };
      });
      // set state machine ListType
      setMachineList([...machineDataListFormat]);
      setMachineListOriginal([...machineDataListFormat]);
      if (machineListOriginal.length === 0 || isSubmitReady) {
        const countAuthMachineNumber = machineDataListFormat.filter(
          (item) => (item.voiceId === voiceId && ['1', '2'].includes(item.voiceReceive)) || item.checked,
        ).length;
        setCountAuthMachine(countAuthMachineNumber);
      }
    }
    // Get voice license count
    const dataLicenseCount: VoiceLicenseCount = await http.get<VoiceLicenseCount>(REST_API.VOICE.GET_LICENSE_COUNT, {
      voiceId,
    });
    if (dataLicenseCount) {
      setCountLicenseLimit(dataLicenseCount.licenseCount);
    }
  }, [isSubmitReady, machineListOriginal, machineListSubmit, listSort]);

  const callApiVoiceSend = async (machineSubmit: MachineItemSubmitType) => {
    const http = new HttpConnection({ dispatch });
    const result = await http.post<boolean>(REST_API.VOICE.POST_VOICE_SEND, {
      ...machineSubmit,
    });

    return result;
  };

  const handleSubmitMachineList = useCallback(async () => {
    setIsSubmitReady(true);
    if (machineListSubmit.length === 0) {
      setOpenConfirmDialog(false);
      setOpenDialogINF254(true);
      return;
    }
    // Submit machine list
    const promises = machineListSubmit.map(callApiVoiceSend);
    const results = await Promise.all(promises);
    setOpenConfirmDialog(false);
    setMachineListSubmit([]);
    if (results.length === machineListSubmit.length) {
      setOpenDialogINF539(true);
    }
  }, [machineListSubmit]);

  const handleClickPrev = () => {
    scroll.scrollMore(-58, {
      duration: 50,
      delay: 0,
      containerId: TABLE_ID,
    });
  };

  const handleClickNext = () => {
    scroll.scrollMore(58, {
      duration: 50,
      delay: 0,
      containerId: TABLE_ID,
    });
  };

  const handleCallApi6 = async () => {
    const http = new HttpConnection({ dispatch });
    const result = await http.get<string>(REST_API.VOICE.GET_VOICE_SEND, {
      voiceId,
    });
    const data = result.split(',').map((item) => item.trim());
    return data;
  };

  const handleChangeFilter = async (ev: React.ChangeEvent<HTMLSelectElement>) => {
    const machineValue = ev.target.value;
    if (machineValue !== CLSFCN.MACHINE_TYPE_FILTER.ALL) {
      // Call api6
      const data = await handleCallApi6();
      if (!data.includes(machineValue)) {
        setOpenDialogINF255(true);
      } else {
        setMachineTypeFilter(machineValue);
      }
    } else {
      setMachineTypeFilter(machineValue);
    }
  };

  const handleChangeSort = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    setListSort(ev.target.value);
  };
  const handleClickVoiceList = () => {
    history.push(redirectLinks.VOICE_LIST);
  };

  // Update machine list
  const updateMachineListSubmit = (row: MachineListType) => {
    const index = machineListSubmit.findIndex((item) => item.boardSerial === row.boardSerial);

    if (index !== -1) {
      // Update existing item
      machineListSubmit.splice(index, 1);
    } else {
      // Add new item
      const newItemSubmit = {
        boardSerial: row.boardSerial,
        stationId: row.leftStationId,
        authFlg: row.checked === false ? 0 : 1,
        voiceId,
        voiceName,
      };
      machineListSubmit.push(newItemSubmit);
    }
    setMachineListSubmit(machineListSubmit);
  };

  const handleCancelWaitingAuth = (row: MachineListType) => {
    setCancelVoiceConfirmDialog(true);
    setVoiceCancelRowSelected({ voiceId: row.voiceId, stationId: row.leftStationId, boardSerial: row.boardSerial });
  };

  // Call api5
  const callApiVoiceReceiveSend = async (row: VoiceCancelItemType) => {
    const http = new HttpConnection({ dispatch });
    const result: boolean = await http.post<boolean>(REST_API.VOICE.VOICE_RECEIVE_SEND, {
      voiceId: row.voiceId,
      boardSerial: row.boardSerial,
      stationId: row.stationId,
    });
    return result;
  };

  const handleCallApiSubmitCancelVoice = async () => {
    setCancelVoiceConfirmDialog(false);
    setIsSubmitReady(true);
    await callApiVoiceReceiveSend(voiceCancelRowSelected);
    handleGetMachineListData().then();
  };

  /**
   * Handle check box
   */
  const handleChangeSelectStation = useCallback(
    (row: MachineListType) => {
      let isNeedUpdate = true;
      const machineDataListFormat = machineListOriginal.map((item) => {
        if (item.boardSerial === row.boardSerial) {
          const isChecked = !item.checked;
          if (isChecked) {
            if (countAuthMachine + 1 > countLicenseLimit) {
              isNeedUpdate = false;
              return { ...item };
            }
            setCountAuthMachine(countAuthMachine + 1);
          } else {
            setCountAuthMachine(countAuthMachine - 1);
          }
          return { ...item, checked: isChecked };
        }
        return item;
      });
      setMachineListOriginal(machineDataListFormat);
      // Update machine list submit
      if (isNeedUpdate) {
        updateMachineListSubmit(row);
      }
    },
    [machineListOriginal, countAuthMachine, countLicenseLimit],
  );

  /**
   * Handle show button next
   */
  const handleShowNextButton = useCallback((isShow: boolean) => {
    setIsShowNextButton(isShow);
  }, []);

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <div className={classes.contents}>
          <Box className={classes.topArea}>
            <div className={classes.buttonReturnDescriptionContent}>
              <Box className="returnButton">
                <ReturnButton onClick={handleClickVoiceList}>ボイス一覧</ReturnButton>
              </Box>
              <Grid item className={classes.description}>
                ボイスを使用する機器に✔を入れ、筐体へ送信してください。
                <br />
                ボイス使用をやめる場合は✔を外し、筐体へ送信してください。
              </Grid>
            </div>
            <Grid container justify="space-between">
              <Grid item className={classes.selectBoxWrap}>
                <Grid container alignItems="flex-end" direction="column">
                  <Grid item>
                    <NormalSelectBox
                      name="filter"
                      dataSource={dataSourceFilter}
                      value={machineTypeFilter}
                      onChange={handleChangeFilter}
                      label="機種"
                    />
                  </Grid>
                  <Grid item className={classes.selectBoxOrder}>
                    <NormalSelectBox
                      name="sort"
                      dataSource={dataSourceSort}
                      value={listSort}
                      onChange={handleChangeSort}
                      label="並べ替え"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <div className={classes.headerH1Container}>
            <div className={classes.headerH1}>{`ボイス名：${voiceName}`}</div>
            <div className={classes.headerH1}>{`${countAuthMachine} / ${countLicenseLimit}`}</div>
          </div>
          <Grid className={classes.previousBtn}>
            {machineList.length > 0 && <PrevNextButton isUp className="prevScroll" onClick={handleClickPrev} />}
          </Grid>
          <VoiceStationTable
            machineList={machineList}
            tableId={TABLE_ID}
            selectVoiceId={voiceId}
            handleShowNextButton={handleShowNextButton}
            handleChangeSelectStation={handleChangeSelectStation}
            handleCancelWaitingAuth={handleCancelWaitingAuth}
          />
          <Grid className={classes.nextBtn}>
            {isShowNextButton && <PrevNextButton className="nextScroll" onClick={handleClickNext} />}
          </Grid>
          <Grid className={classes.decision}>
            <DecisionButton onClick={() => setOpenConfirmDialog(true)}>筐体へ送信</DecisionButton>
          </Grid>
        </div>
        <ConfirmDialogWithTitle
          open={openConfirmDialog}
          title="認証情報を筐体に送信します。"
          msg={MESSAGES.INF253}
          addClass={{ contentText: classes.contentConfigDialogText }}
          onOk={() => handleSubmitMachineList()}
          onCancel={() => setOpenConfirmDialog(false)}
          okBtnLabel="送信"
          cancelBtnLabel="戻る"
        />
        <ConfirmDialog
          open={cancelVoiceConfirmDialog}
          msg={MESSAGES.INF252}
          onOk={() => handleCallApiSubmitCancelVoice()}
          onCancel={() => setCancelVoiceConfirmDialog(false)}
        />
        <InfoDialog
          open={openDialogINF254}
          msg={MESSAGES.INF254}
          closeFunc={() => setOpenDialogINF254(false)}
          closeBtnLabel="閉じる"
        />
        <InfoDialog open={openDialogINF255} msg={MESSAGES.INF255} closeFunc={() => setOpenDialogINF255(false)} />
        <InfoDialogWithTitle
          open={openDialogINF539}
          title="筐体に送信しました。"
          msg={MESSAGES.INF539}
          closeFunc={async () => {
            await handleGetMachineListData();
            setOpenDialogINF539(false);
          }}
          closeBtnLabel="閉じる"
        />
      </Layout>
    </ScreenContext.Provider>
  );
};

export default VoiceStationSelect;
