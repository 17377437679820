import { makeStyles, Table, TableBody, TableCell, TableContainer, TableRow, Theme } from '@material-ui/core';
import React, { useCallback } from 'react';
import NormalButton from 'components/system/atoms/buttons/NormalButton';
import MachineStationTableHead from 'components/data/organisms/tables/common/MachineStationTableHead';
import { VoiceTableRowType, VoiceTableType, VoiceType } from 'types/voice/voiceType';
import { formatDate } from 'utils/datetime.helper';
import COMMON from 'constants/common';
import HttpConnection from 'utils/httpConnection';
import { REST_API } from 'constants/apiUrls';
import { useAppDispatch } from 'redux/hooks';
import { useHistory } from 'react-router-dom';
import redirectLinks from 'constants/redirectLinks';
import ConfirmDialogWithTitle from '../../../system/organisms/dialogs/ConfirmDialogWithTitle';

/* ************ Style ************ */
const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    height: '494px',
    width: '1100px',
  },
  table: {
    tableLayout: 'fixed',
    borderCollapse: 'separate',
    '& th,td': {
      border: '1px solid',
      borderRightColor: theme.base.tableBorder,
      borderLeftColor: theme.base.tableBorder,
    },
    '& th': {
      textAlign: 'center',
      fontSize: '18px',
      lineHeight: '28px',
      padding: 0,
    },
    '& th:last-child': {
      textAlign: 'left',
      paddingLeft: '176px',
      // レイアウトに合わせるため、1行目を字下げして中央揃えの見た目にする
      textIndent: '-2em',
    },
    '& tbody tr': {
      height: '72px',
    },
    '& tbody tr:nth-child(odd)': {
      backgroundColor: theme.base.tableRowPrimary,
    },
    '& tbody tr:nth-child(even)': {},
    '& td': {
      fontSize: '16px',
      lineHeight: '23px',
      '& .cellStyle': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'fade',
      },
    },
  },
  confDetail: {
    backgroundColor: '#B8FFC9',
    borderColor: '#B8FFC9',
    color: theme.palette.common.black,
    height: '40px',
    width: '120px',
    boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.5)',
    '&:hover': {
      backgroundColor: '#B8FFC9',
      boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.5)',
    },
  },
  confDetailDisable: {
    backgroundColor: '#7F7F7F',
    borderColor: '#7F7F7F',
    color: theme.palette.common.white,
    height: '40px',
    width: '120px',
    '&:hover': {
      backgroundColor: '#7F7F7F',
      cursor: 'default',
    },
  },
  '&:hover': {
    backgroundColor: '#B8FFC9',
    boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.5)',
  },
  firstCell: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dateCell: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    lineHeight: '15px',
  },
  cellType: {
    padding: '1px 8px',
  },
  modelContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
  },
  machineContainerLabel: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    width: 204,
    gap: 5,
  },
  btnLabel: {
    whiteSpace: 'nowrap',
    height: 26,
    width: 64,
    padding: 3,
    fontSize: 14,
    borderRadius: 4,
    textAlign: 'center',
  },
  firstContent: {
    lineHeight: '30px',
  },
  btnLabelDisable: {
    border: 1,
    borderColor: '#707070',
    background: 'linear-gradient(to bottom right, #BEBEBE calc(50% - 1px), white , #BEBEBE calc(50% + 1px) )',
  },
  btnLastButton: {
    width: 74,
  },
  machineType0: {
    backgroundColor: '#01C3D5',
  },
  machineType1: {
    backgroundColor: '#FF4386',
  },
  machineType2: {
    backgroundColor: '#32D7A5',
  },
  machineType3: {
    backgroundColor: '#FFE854',
  },
  machineType4: {
    backgroundColor: '#FFB1E5',
  },
}));

/* ******** Control Component ******** */
const VoiceListTableRow: React.FC<VoiceTableRowType> = React.memo(({ row, handleDownloadFile }) => {
  const classes = useStyles();
  const history = useHistory();

  // Handle select target device
  const onClickSelectTargetDevice = useCallback((voice: VoiceType) => {
    // console.log('Hanle click select target device here', voice.voiceId);
    history.push(redirectLinks.VOICE_SELECT_STATION, {
      voiceId: voice.voiceId,
      voiceName: voice.voiceName,
    });
  }, []);

  // Render button machine type
  const renderButton = useCallback(
    (values: Array<string>) => (
      <div className={classes.machineContainerLabel}>
        <div
          className={`${classes.btnLabel} ${
            values.includes(COMMON.MACHINE_TYPE.CLENA3_0) ? classes.machineType0 : classes.btnLabelDisable
          }`}>
          クレナ3
        </div>
        <div
          className={`${classes.btnLabel} ${
            values.includes(COMMON.MACHINE_TYPE.JACK) ? classes.machineType1 : classes.btnLabelDisable
          }`}>
          ジャック
        </div>
        <div
          className={`${classes.btnLabel} ${
            values.includes(COMMON.MACHINE_TYPE.GRAND) ? classes.machineType2 : classes.btnLabelDisable
          }`}>
          グラン
        </div>
        {/* <div
          className={`${classes.btnLabel} ${
            values.includes(COMMON.MACHINE_TYPE.MULTI) ? classes.machineType3 : classes.btnLabelDisable
          }`}>
          マルチ
        </div> */}
        {/* <div
          className={`${classes.btnLabel} ${classes.btnLastButton} ${
            values.includes(COMMON.MACHINE_TYPE.SWITLAND) ? classes.machineType4 : classes.btnLabelDisable
          }`}>
          スウィート
        </div> */}
      </div>
    ),
    [],
  );

  const renderMachineType = useCallback((machineType) => {
    const buttonValues = machineType
      .split(',')
      .map((value: string) => value.trim())
      .filter(Boolean);

    return renderButton(buttonValues);
  }, []);

  // Is enable button
  const isEnableButton = (rowData: VoiceType) =>
    !(new Date(rowData.voiceDownloadEnd) < new Date() || new Date(rowData.voiceDownloadStart) > new Date());

  return (
    <TableRow key={`table-row-key-${row.voiceId}`}>
      <TableCell className={classes.cellType}>
        <div className={classes.firstCell}>
          <div className={classes.firstContent}>
            <div className="cellStyle" style={{ maxWidth: '350px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {row.voiceName}
            </div>
            <div className="cellStyle" style={{ maxWidth: '350px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              （{row.voiceFileName}）
            </div>
          </div>
          <div style={{ flexShrink: 0 }}>
            <NormalButton
              className={isEnableButton(row) ? classes.confDetail : classes.confDetailDisable}
              onClick={() => (isEnableButton(row) ? handleDownloadFile(row) : {})}>
              ダウンロード
            </NormalButton>
          </div>
        </div>
      </TableCell>
      <TableCell className={classes.cellType}>
        <div className={classes.dateCell}>
          <div>{formatDate(new Date(row.voiceDownloadStart))}</div>
          <div>～</div>
          <div>{formatDate(new Date(row.voiceDownloadEnd))}</div>
        </div>
      </TableCell>
      <TableCell className={classes.cellType}>
        <div className={classes.dateCell}>
          <div>{formatDate(new Date(row.voiceActivateStart))}</div>
          <div>～</div>
          <div>{formatDate(new Date(row.voiceActivateEnd))}</div>
        </div>
      </TableCell>
      <TableCell className={classes.cellType}>
        <div className={classes.modelContainer}>
          {renderMachineType(row.machineType)}
          <div>
            <NormalButton className={classes.confDetail} onClick={() => onClickSelectTargetDevice(row)}>
              対象機器選択
            </NormalButton>
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
});

/* ******** Main Component ******** */
const VoiceListTable: React.FC<VoiceTableType> = ({ voiceList, tableId }) => {
  const classes = useStyles();
  const [downloadConfirmDialog, setDownloadConfirmDialog] = React.useState<boolean>(false);
  const [voiceDownload, setVoiceDownload] = React.useState<VoiceType | null>(null);
  const [dialogMessageDownload, setDialogMessageDownload] = React.useState<string>('');
  const dispatch = useAppDispatch();

  const callLogApi = async (id: number) => {
    const http = new HttpConnection({ dispatch });
    http.postSecond(REST_API.VOICE_DOWNLOAD.VOICE_LOG, {
      id,
    });
  };
  /* ************ Event ************ */

  // テーブル列定義 idは利用しないが定義する。
  const headerColumns = [
    { id: 'voiceName', title: 'ボイス名\n（ファイル名）', width: 500 },
    { id: 'downloadPeriod', title: 'ダウンロード\n可能期間', width: 118 },
    { id: 'voiceValidityPeriod', title: `ボイス\n有効期間`, width: 118 },
    { id: 'CompatibleModels', title: `対応機種`, width: '100%' },
  ];

  // Handle button download and save file
  const onClickConfDetail = useCallback(async () => {
    setDownloadConfirmDialog(false);
    if (voiceDownload === null) return;
    await callLogApi(voiceDownload.voiceId);
    // Create an anchor element dynamically
    const url = `${COMMON.CONTENT_URL}${voiceDownload.path}`;
    // Fetch the file as a Blob
    const response = await fetch(url);
    const blob = await response.blob();

    // Create a link element with a blob URL
    const blobUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = voiceDownload.voiceFileName;

    // Append to the DOM, click to initiate download, and then remove it
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [voiceDownload]);

  const handleDownloadFile = useCallback((row: VoiceType) => {
    setDownloadConfirmDialog(true);
    setVoiceDownload(row);
    const message = `ボイス名：${row.voiceName}\t\nファイル名：${row.voiceFileName}`;
    setDialogMessageDownload(message);
  }, []);

  return (
    <>
      <TableContainer id={tableId} className={classes.container}>
        <Table className={classes.table} stickyHeader aria-label="sticky table">
          <MachineStationTableHead columns={headerColumns} />
          <TableBody>
            {voiceList.map((row, index) => (
              <VoiceListTableRow key={String(index)} row={row} index={index} handleDownloadFile={handleDownloadFile} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmDialogWithTitle
        title="このボイスをダウンロードしますか？"
        open={downloadConfirmDialog}
        msg={dialogMessageDownload}
        onOk={() => onClickConfDetail()}
        onCancel={() => setDownloadConfirmDialog(false)}
      />
    </>
  );
};

export default VoiceListTable;
