import React, { memo } from 'react';
import { Box, Grid, Table, TableRow, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import sideToggleSwapGray from 'assets/images/side.toggle.swap-gra.svg';
import sideToggleSwapScreen from 'assets/images/timing25.toggle.swap.svg';
import COMMON from 'constants/common';
import { getClassificationName, getSupportConf } from 'utils/common.helper';
import CLSFCN from 'constants/classification';
import {
  AreaConfGrandType,
  NewPayoutInfoSupportGrandType,
  NewServiceGraphType,
  ShiftOperationType,
  ShowAreaColorType,
} from 'types/machineConf/machineConfType';
import arrowBottomOn from 'assets/images/arrowBottomOn.svg';
import arrowBottomOff from 'assets/images/arrowBottomOff.svg';
import arrowIconLeft from 'assets/images/arrowLeft.svg';
import arrowIconUp from 'assets/images/arrowUp.svg';
import graph from 'assets/images/newServiceConfGraph.svg';
import armPowerTripleIcon from 'assets/images/armPowerTripleIcon.svg';
import { themeColor } from 'constants/theme';
import { useAppSelector } from 'redux/hooks';
import redirectLinks from 'constants/redirectLinks';
import { useHistory } from 'react-router-dom';
import { SaveDetailInfoType } from 'types/machineConf/stationSelectType';
import ShiftPanelReviewGrand from './ShiftPanelReviewGrand';

/* ************ Contants ************ */
const COLORS = {
  BASIC_ARM: '#00B050',
  PLAY_COUNT: '#3086FF',
  LIMIT_ARM: '#FF3E3E',
  LIMIT_PLAY_COUNT: '#AB47BC',
};

type StyleProps = {
  isLeft: boolean;
  powerIconLeft?: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  container: {
    height: '255px',
    width: '322px',
    padding: '3px 10px',
  },
  title: {
    position: 'relative',
    fontSize: '24px',
    textAlign: 'left',
    fontWeight: 'bold',
    '& span': {
      display: 'inline-block',
      margin: '0 0 0 15px',
      fontSize: '17px',
    },
    marginBottom: '7px',
  },
  frame: {
    flexDirection: 'column',
    alignItems: 'center',
    '& .inner': {
      display: 'flex',
    },
    '& .areaTable': {
      columnGap: '10px',
    },
    '& .between': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  powerIcon: {
    position: 'relative',
    top: '-7px',
    width: '142px',
    armPowerText: {
      position: 'absolute',
      width: '61px',
      height: '34px',
      fontSize: '15px',
      lineHeight: '16px',
      top: 0,
      left: 0,
    },
    '& .icon': {
      margin: '0 auto',
      width: '53px',
      height: '54px',
      marginTop: '-20px',
    },
    '& .square': {
      width: '44px',
      height: '35px',
      lineHeight: '35px',
      fontSize: '14px',
      background: '#FFF',
      borderRadius: '2px',
      display: 'flex',
      position: 'absolute',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .value': {
      width: '44px',
      height: '35px',
      lineHeight: '35px',
      fontSize: '14px',
      background: '#FFF',
      borderRadius: '2px',
      display: 'flex',
      position: 'absolute',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .pos-left': {
      left: '0px',
      marginTop: '5px',
    },
    '& .pos-middle': {
      left: '49px',
      marginTop: '5px',
    },
    '& .pos-right': {
      right: '10px',
      marginTop: '5px',
    },
    '& .pos-left-r': {
      left: '10px',
      marginTop: '5px',
    },
    '& .pos-right-r': {
      right: '0px',
      marginTop: '5px',
    },
    '& .bottom': {
      borderTop: `2px solid ${theme.palette.common.black}`,
      padding: '4px 0 0',
      margin: '50px 0 0',
      fontSize: 24,
      textAlign: 'center',
    },
    '& .top': {
      fontSize: 19,
      textAlign: 'center',
      marginBottom: '-2px',
    },
    '& .mt-2': {
      marginTop: '2px',
    },
  },
  powerIconService: {
    position: 'relative',
    width: '150px',
    '& .icon': {
      margin: '0 auto',
    },
    '& .valueTop': {
      width: '66px',
      height: '46px',
      lineHeight: '44px',
      fontSize: '24px',
      background: '#FFF',
      borderRadius: '2px',
      display: 'flex',
      position: 'absolute',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .pos-left': {
      left: '0px',
    },
    '& .pos-right': {
      right: '10px',
    },
    '& .pos-left-r': {
      left: '10px',
    },
    '& .pos-right-r': {
      right: '0px',
    },
    '& .bottom': {
      borderTop: `2px solid ${theme.palette.common.black}`,
      padding: '4px 0 0',
      margin: '60px 0 0',
      fontSize: 24,
      textAlign: 'center',
    },
    '& .top': {
      fontSize: 19,
      textAlign: 'center',
    },
  },
  border: {
    border: ({ isLeft }) =>
      `2px solid ${isLeft ? theme.grandStation.contentsBorder : theme.rightStation.contentsBorder}`,
  },
  armBorder: {
    border: '1px solid #575757',
    borderRadius: '2px',
  },
  borderRadius: {
    borderRadius: 4,
  },
  operationConfBg: {
    background: '#ebebeb',
    boxShadow: '5px 5px 3px rgba(0, 0, 0, 0.2)',
  },
  cursorPoint: {
    cursor: 'pointer',
  },
  newServiceImgContent: {
    marginTop: '12px',
    '& .icon': {
      margin: '0 auto',
    },
  },
  shiftContainer: {
    height: '180px',
    width: '180px',
  },
  shiftContent: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 5,
  },
  shiftOperatorLeftContent: {
    width: '99px',
    alignSelf: 'center',
    '& .contentBox': {
      background: '#ffffff',
      width: '99px',
      height: '44px',
      lineHeight: '44px',
      fontSize: 14,
      border: '1px solid #575757',
      borderRadius: 2,
      textAlign: 'center',
      whiteSpace: 'nowrap',
    },
  },
  shiftOperatorRightContent: {
    display: 'flex',
    justifyContent: 'center',
    width: '200px',
  },
  arrowIcon: {
    position: 'absolute',
    top: 10,
    right: ({ powerIconLeft }) => (powerIconLeft ? 20 : 10),
  },
  getSupportTable: {
    '&.withArea': {
      width: '142px',
    },
    '&.noArea': {
      width: '150px',
    },
    '&.mt5': {
      marginTop: '5px',
    },
  },
  tableLabel: {
    backgroundColor: '#D2F9FF',
    border: '1px solid #707070',
    textAlign: 'center',
    fontSize: '15px',
    '&.left': {
      padding: '0 4px',
      textAlign: 'left',
    },
  },
  tableLabelWrap: {
    backgroundColor: '#EEEEEE',
    border: '1px solid #707070',
    textAlign: 'center',
    fontSize: '15px',
    '&.left': {
      padding: '0 4px',
      textAlign: 'left',
    },
  },
  operationConfNewServiceWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
  },
  tableValue: {
    backgroundColor: '#ffffff',
    border: '1px solid #707070',
    textAlign: 'center',
    fontSize: '15px',
  },
  getSupport: {
    height: '48px',
    width: '73px',
    fontSize: '15px',
    '&.label': {
      width: '60%',
    },
  },
  assistPlay: {
    fontSize: '14px',
    textAlign: 'left',
  },
  assistPlayWrap: {
    paddingLeft: '10px',
  },
  getSupportWrap: {
    height: '28px',
    width: '93px',
    fontSize: '15px',
  },
  resetTimingLabel: {
    height: '24px',
    width: '140px',
    fontSize: '14px',
    lineHeight: '20px',
    borderRight: 'none',
  },
  resetTimingValue: {
    height: '24px',
    minWidth: '112px',
    fontSize: '14px',
    lineHeight: '20px',
  },
  areaTable: {
    fontSize: '19px',
    marginTop: '7px',
    width: '142px',
    '& .areaTableHead': {
      height: '30px',
    },
    '& .areaTableBody': {
      height: '36px',
    },
    '& .headArea': {
      width: '37px',
    },
    '& .headLeftRight': {
      width: '36px',
    },
  },
  grabPowerTitle: {
    fontSize: '16px',
    marginTop: '7px',
  },
  bgWhite: {
    background: '#ffffff',
    width: '28px',
    height: '28px',
    border: '1px solid #707070',
    margin: '0 auto',
  },
  bgGreen: {
    background: '#C9F2E2',
    width: '28px',
    height: '28px',
    border: '1px solid #707070',
    margin: '0 auto',
  },
  bgPurple: {
    background: '#EBCFEB',
    width: '28px',
    height: '28px',
    border: '1px solid #707070',
    margin: '0 auto',
  },
  bgYellow: {
    background: '#FFFDB5',
    width: '28px',
    height: '28px',
    border: '1px solid #707070',
    margin: '0 auto',
  },
  bgRed: {
    background: '#FFC8C8',
    width: '28px',
    height: '28px',
    border: '1px solid #707070',
    margin: '0 auto',
  },
  bgBlue: {
    background: '#B4DFFF',
    width: '28px',
    height: '28px',
    border: '1px solid #707070',
    margin: '0 auto',
  },
  grabPower: {
    marginTop: '14px',
  },
  timing: {
    borderRadius: 2,
    border: '1px solid #575757',
    backgroundColor: '#ffffff',
    width: '134px',
    height: '202px',
    position: 'relative',
    '& .iconLateralMoving': {
      position: 'absolute',
      top: '6px',
      left: '22px',
      width: '25px',
      height: '25px',
    },
    '& .icon0': {
      position: 'absolute',
      top: '6px',
      right: '32px',
      width: '25px',
      height: '25px',
    },
    '& .icon25': {
      position: 'absolute',
      top: '50px',
      right: '32px',
      width: '25px',
      height: '25px',
    },
    '& .icon50': {
      position: 'absolute',
      top: '90px',
      right: '32px',
      width: '25px',
      height: '25px',
    },
    '& .icon75': {
      position: 'absolute',
      top: '130px',
      right: '32px',
      width: '25px',
      height: '25px',
    },
    '& .icon100': {
      position: 'absolute',
      top: '170px',
      right: '32px',
      width: '25px',
      height: '25px',
    },
    '& .labelLateralMoving': {
      position: 'absolute',
      top: '36px',
      left: '14px',
      fontSize: '14px',
      lineHeight: '9px',
      color: '#000000',
    },
    '& .label0': {
      position: 'absolute',
      top: '10px',
      left: '102px',
      fontSize: '15px',
    },
    '& .label25': {
      position: 'absolute',
      top: '54px',
      left: '102px',
      fontSize: '15px',
    },
    '& .label50': {
      position: 'absolute',
      top: '94px',
      left: '102px',
      fontSize: '15px',
    },
    '& .label75': {
      position: 'absolute',
      top: '134px',
      left: '102px',
      fontSize: '15px',
    },
    '& .label100': {
      position: 'absolute',
      top: '174px',
      left: '102px',
      fontSize: '15px',
    },
    '& .arrowIconLeft': {
      position: 'absolute',
      top: '14px',
      left: '54px',
      width: '15px',
    },
    '& .arrowIconUpTo0': {
      position: 'absolute',
      top: '35px',
      right: '40px',
      height: '15px',
    },
    '& .arrowIconUpTo25': {
      position: 'absolute',
      top: '75px',
      right: '40px',
      height: '15px',
    },
    '& .arrowIconUpTo50': {
      position: 'absolute',
      top: '115px',
      right: '40px',
      height: '15px',
    },
    '& .arrowIconUpTo75': {
      position: 'absolute',
      top: '155px',
      right: '40px',
      height: '15px',
    },
  },
  direction: {
    position: 'absolute',
    width: '50px',
    height: '147px',
    border: '1px solid #575757',
    borderRadius: '2px',
    backgroundColor: '#ffffff',
    padding: '5px 10px 0 10px',
    top: '49px',
    left: '10px',
  },
  directionText: {
    fontSize: '14px',
    lineHeight: '15px',
    color: '#000000',
  },
  directionBottom: {
    width: '22px',
  },
  directionBottomRight: {
    width: '22px',
    transform: 'rotate(-45deg)',
    marginTop: '-3px',
  },
  directionBottomLeft: {
    width: '22px',
    transform: 'rotate(45deg)',
    marginTop: '-3px',
  },
  bottomContent: {
    display: 'flex',
    justifyContent: 'space-between',
    '& .bottomLeft': {
      display: 'flex',
    },
    '& .graphArea': {
      width: '322px',
      height: '230px',
      position: 'relative',
      '& .graphImage': {
        position: 'absolute',
        top: '34%',
        left: '49.5%',
        transform: 'translate(-50%,-50%)',
      },
      '& .graphBasicAPIcon': {
        width: '36px',
        position: 'absolute',
        top: '6px',
        left: '56px',
      },
      '& .graphLimitAPIcon': {
        width: '36px',
        position: 'absolute',
        top: '14px',
        left: '226px',
      },
      '& .graphBoxLLarge, & .graphBoxLarge, & .graphBoxMedium, & .graphBoxSmall': {
        position: 'absolute',
        fontSize: '14px',
        textAlign: 'center',
        backgroundColor: '#FFF',
        border: '1px solid #575757',
        borderRadius: '4px',
      },
      '& .graphBoxLLarge': {
        width: '40px',
        height: '21px',
      },
      '& .graphBoxLarge': {
        width: '38px',
        height: '21px',
      },
      '& .graphBoxMedium': {
        width: '38px',
        height: '21px',
      },
      '& .graphBoxSmall': {
        width: '29px',
        height: '21px',
      },
      '& .graphBasicLeft': {
        top: '43px',
        left: '10px',
      },
      '& .graphBasicMiddle': {
        top: '43px',
        left: '55px',
      },
      '& .graphBasicRight': {
        top: '43px',
        left: '99px',
      },
      '& .graphBasicAO': {
        top: '67px',
        left: '55px',
      },
      '& .graphLimitLeft': {
        top: '54px',
        left: '182px',
      },
      '& .graphLimitMiddle': {
        top: '54px',
        left: '224px',
      },
      '& .graphLimitRight': {
        top: '54px',
        left: '266px',
      },
      '& .graphLimitAO': {
        top: '78px',
        left: '224px',
      },
      '& .graphPC': {
        top: '152px',
        left: '122px',
      },
      '& .graphLimitPC': {
        top: '152px',
        left: '168px',
      },
    },
    '& .resetTimingArea': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '430px',
      height: '124px',
      padding: '8px',
      marginLeft: '10px',
    },
    '& .bottomRight': {
      marginTop: 'auto',
      marginLeft: 'auto',
    },
  },
  // ステーション別Style
  leftStyle: {
    borderColor: themeColor.grandDarkColor,
    '&:before': {
      borderLeftColor: themeColor.grandDarkColor,
    },
    '&:after': {
      borderLeftColor: '#FFF',
    },
  },
  // Color
  basicArmColor: {
    color: COLORS.BASIC_ARM,
  },
  playCountColor: {
    color: COLORS.PLAY_COUNT,
  },
  limitArmColor: {
    color: COLORS.LIMIT_ARM,
  },
  limitPlayCountColor: {
    color: COLORS.LIMIT_PLAY_COUNT,
  },
}));

// Define type Props
type PowerIconProps = {
  textTop?: string;
  valueLeft: string;
  valueRight: string;
  valueCenter: string;
  textBottom?: string;
  isLeft: boolean;
  powerIconLeft: boolean;
  justOne?: boolean;
};

type NewPayoutInfoSupportProps = {
  title: string;
  isLeft: boolean;
  operationConf: string;
  payoutInfoSupport: NewPayoutInfoSupportGrandType;
  newServiceGraph: NewServiceGraphType;
  shiftOperation: ShiftOperationType;
  areaConf?: AreaConfGrandType;
  savedConfProps?: SaveDetailInfoType;
};

const PowerIcon: React.FC<PowerIconProps> = (props) => {
  const { valueLeft, valueRight, valueCenter, textBottom, textTop, isLeft, powerIconLeft, justOne } = props;
  const classes = useStyles({ isLeft, powerIconLeft });
  const classPosLeft = powerIconLeft || justOne ? 'pos-left' : 'pos-left-r';
  const classPosRight = powerIconLeft && !justOne ? 'pos-right' : 'pos-right-r';

  return (
    <Box className={classes.powerIcon}>
      <Box className={classes.armPowerText}>
        つかみ時の
        <br />
        パワー
      </Box>
      <img src={armPowerTripleIcon} alt="armPowerTripleIcon" className="icon" />
      {textTop ? <Box className="top">{textTop}</Box> : <div className="mt-2"> </div>}
      <Box>
        <span className={`value ${classes.armBorder} ${classPosLeft}`}>{valueLeft}</span>
        <span className={`value ${classes.armBorder} pos-middle`}>{valueCenter}</span>
        <span className={`value ${classes.armBorder} ${classPosRight}`}>{valueRight}</span>
      </Box>
      {textBottom ? <Box className="bottom">{textBottom}</Box> : <></>}
    </Box>
  );
};

const Timing = () => {
  const classes = useStyles({ isLeft: true });

  const stationStyle = classes.leftStyle;
  const operationConf = useAppSelector((state) => state.machineConf.machineGrandConf.operationConf);
  const areaConf = useAppSelector((state) => state.machineConf.machineGrandConf.areaConf);

  return (
    <>
      <Box className={classes.bottomContent}>
        <Box className="bottomLeft">
          <Box className={`${classes.baseArea} graphArea ${stationStyle}`}>
            {/* グラフのラベルと画像 */}
            <img src={graph} alt="graph" className="graphImage" />
            <img src={armPowerTripleIcon} alt="armPowerTripleIcon" className="graphBasicAPIcon" />
            <Box className={`${classes.basicArmColor} graphBoxLarge graphBasicLeft`}>{areaConf?.lPower1}</Box>
            <Box className={`${classes.basicArmColor} graphBoxLarge graphBasicMiddle`}>{areaConf?.cPower1}</Box>
            <Box className={`${classes.basicArmColor} graphBoxLarge graphBasicRight`}>{areaConf?.rPower1}</Box>
            <Box className={`${classes.basicArmColor} graphBoxLLarge graphBasicAO`}>{areaConf?.armOpen}%</Box>
            <img src={armPowerTripleIcon} alt="armPowerTripleIcon" className="graphLimitAPIcon" />
            <Box className={`${classes.limitArmColor} graphBoxLarge graphLimitLeft`}>{operationConf?.limitLPower}</Box>
            <Box className={`${classes.limitArmColor} graphBoxLarge graphLimitMiddle`}>
              {operationConf?.limitCPower}
            </Box>
            <Box className={`${classes.limitArmColor} graphBoxLarge graphLimitRight`}>{operationConf?.limitRPower}</Box>
            <Box className={`${classes.limitArmColor} graphBoxLLarge graphLimitAO`}>{areaConf?.armOpen}%</Box>
            <Box className={`${classes.playCountColor} graphBoxSmall graphPC`}>{operationConf?.playCount}</Box>
            <Box className={`${classes.limitPlayCountColor} graphBoxMedium graphLimitPC`}>
              {operationConf?.limitPlayCount}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const newPaymentInfoSupportContent = (
  isLeft: boolean,
  operationConf: string,
  payoutInfoSupport: NewPayoutInfoSupportGrandType,
  newServiceGraph: NewServiceGraphType,
  shiftOperation: ShiftOperationType,
  areaConf?: AreaConfGrandType,
) => {
  const classes = useStyles({ isLeft });
  let contentPaymentInfo: JSX.Element;
  const areaConfFlg = areaConf?.areaConf ?? COMMON.SWITCH.OFF;
  const { showAreaColor } = useAppSelector((state) => state.machineConf);
  const supportConf = getSupportConf(payoutInfoSupport.getSupport, payoutInfoSupport.supportConf);

  switch (operationConf) {
    case COMMON.OPERATION_CONF.NO_CONF:
      contentPaymentInfo = <></>;
      break;
    case COMMON.OPERATION_CONF.NEW_PAYOUT_SUPPORT:
      contentPaymentInfo = <></>;
      if (areaConfFlg === COMMON.SWITCH.ON) {
        // プレイヤーサポート設定（Ver.E以上）& エリア設定ON時
        const TABLE1 = [0, 1, 2];
        const TABLE2 = [3, 4, 5];
        const COLOR = [
          classes.bgWhite,
          classes.bgGreen,
          classes.bgPurple,
          classes.bgYellow,
          classes.bgRed,
          classes.bgBlue,
        ];

        const grabLPowerList = [
          payoutInfoSupport.grabLPower1,
          payoutInfoSupport.grabLPower2,
          payoutInfoSupport.grabLPower3,
          payoutInfoSupport.grabLPower4,
          payoutInfoSupport.grabLPower5,
          payoutInfoSupport.grabLPower6,
        ];
        const grabRPowerList = [
          payoutInfoSupport.grabRPower1,
          payoutInfoSupport.grabRPower2,
          payoutInfoSupport.grabRPower3,
          payoutInfoSupport.grabRPower4,
          payoutInfoSupport.grabRPower5,
          payoutInfoSupport.grabRPower6,
        ];
        const grabCPowerList = [
          payoutInfoSupport.grabCPower1,
          payoutInfoSupport.grabCPower2,
          payoutInfoSupport.grabCPower3,
          payoutInfoSupport.grabCPower4,
          payoutInfoSupport.grabCPower5,
          payoutInfoSupport.grabCPower6,
        ];

        contentPaymentInfo = (
          <Box>
            <Box>
              <Box className="between">
                <Box>
                  <Table className={`${classes.getSupportTable} withArea`}>
                    <TableRow>
                      <td className={`${classes.tableLabelWrap} ${classes.getSupportWrap}`}>獲得サポート</td>
                      <td className={`${classes.tableValue}`}>
                        {getClassificationName(CLSFCN.GET_SUPPORT_CONF.CATEGORY_CD, supportConf)}
                      </td>
                    </TableRow>
                  </Table>
                  <div className={classes.grabPowerTitle}>つかみ時のパワー</div>
                </Box>
                <Table className={`${classes.getSupportTable} withArea`}>
                  <TableRow>
                    <td className={`${classes.tableLabelWrap} ${classes.assistPlay} left label`}>連続プレイアシスト</td>
                  </TableRow>
                  <TableRow>
                    <td className={`${classes.tableValue} ${classes.assistPlay}`}>
                      <Grid container>
                        <Grid item xs={5} className={classes.assistPlayWrap}>
                          {getClassificationName(CLSFCN.ON_OFF.CATEGORY_CD, payoutInfoSupport.assist)}
                        </Grid>
                        <Grid item>{payoutInfoSupport.assistCost}円</Grid>
                      </Grid>
                    </td>
                  </TableRow>
                </Table>
              </Box>
              <Box className="inner areaTable between">
                <Table className={classes.areaTable}>
                  <TableRow className="areaTableHead">
                    <td className={`${classes.tableLabel} headArea`}>ｴﾘｱ</td>
                    <td className={`${classes.tableLabel} headLeftRight`}>左</td>
                    <td className={`${classes.tableLabel} headLeftRight`}>中</td>
                    <td className={`${classes.tableLabel} headLeftRight`}>右</td>
                  </TableRow>
                  {TABLE1.map((value) => {
                    const area = `area${value}` as keyof ShowAreaColorType;
                    return (
                      <TableRow className={`${classes.tableLabel} areaTableBody`}>
                        <td className={classes.tableValue}>
                          <Box className={COLOR[value]}>&nbsp;</Box>
                        </td>
                        <td className={classes.tableValue}>
                          {(showAreaColor[area] && grabLPowerList[value]) || COMMON.BLANK1}
                        </td>
                        <td className={classes.tableValue}>
                          {(showAreaColor[area] && grabCPowerList[value]) || COMMON.BLANK1}
                        </td>
                        <td className={classes.tableValue}>
                          {(showAreaColor[area] && grabRPowerList[value]) || COMMON.BLANK1}
                        </td>
                      </TableRow>
                    );
                  })}
                </Table>
                <Table className={classes.areaTable}>
                  <TableRow className="areaTableHead">
                    <td className={`${classes.tableLabel} headArea`}>ｴﾘｱ</td>
                    <td className={`${classes.tableLabel} headLeftRight`}>左</td>
                    <td className={`${classes.tableLabel} headLeftRight`}>中</td>
                    <td className={`${classes.tableLabel} headLeftRight`}>右</td>
                  </TableRow>
                  {TABLE2.map((value) => {
                    const area = `area${value}` as keyof ShowAreaColorType;
                    return (
                      <TableRow className={`${classes.tableLabel} areaTableBody`}>
                        <td className={classes.tableValue}>
                          <Box className={COLOR[value]}>&nbsp;</Box>
                        </td>
                        <td className={classes.tableValue}>
                          {(showAreaColor[area] && grabLPowerList[value]) || COMMON.BLANK1}
                        </td>
                        <td className={classes.tableValue}>
                          {(showAreaColor[area] && grabCPowerList[value]) || COMMON.BLANK1}
                        </td>
                        <td className={classes.tableValue}>
                          {(showAreaColor[area] && grabRPowerList[value]) || COMMON.BLANK1}
                        </td>
                      </TableRow>
                    );
                  })}
                </Table>
              </Box>
            </Box>
          </Box>
        );
      } else if (areaConfFlg === COMMON.SWITCH.OFF) {
        // プレイヤーサポート設定（Ver.E以上）& エリア設定OFF時
        contentPaymentInfo = (
          <Box>
            <Box>
              <Box className="between">
                <Box>
                  <Table className={`${classes.getSupportTable} withArea`}>
                    <TableRow>
                      <td className={`${classes.tableLabelWrap} ${classes.getSupportWrap}`}>獲得サポート</td>
                      <td className={`${classes.tableValue}`}>
                        {getClassificationName(CLSFCN.GET_SUPPORT_CONF.CATEGORY_CD, supportConf)}
                      </td>
                    </TableRow>
                  </Table>

                  <Table className={`${classes.getSupportTable} withArea mt5`}>
                    <TableRow>
                      <td className={`${classes.tableLabelWrap} ${classes.assistPlay} left label`}>
                        連続プレイアシスト
                      </td>
                    </TableRow>
                    <TableRow>
                      <td className={`${classes.tableValue} ${classes.assistPlay}`}>
                        <Grid container>
                          <Grid item xs={5} className={classes.assistPlayWrap}>
                            {getClassificationName(CLSFCN.ON_OFF.CATEGORY_CD, payoutInfoSupport.assist)}
                          </Grid>
                          <Grid item>{payoutInfoSupport.assistCost}円</Grid>
                        </Grid>
                      </td>
                    </TableRow>
                  </Table>
                  <Box className={classes.grabPower}>
                    <PowerIcon
                      isLeft={isLeft}
                      valueLeft={payoutInfoSupport.grabLPower1.toString()}
                      valueRight={payoutInfoSupport.grabRPower1.toString()}
                      valueCenter={payoutInfoSupport.grabCPower1.toString()}
                      powerIconLeft
                      justOne
                    />
                  </Box>
                </Box>
                <Box className={classes.timing}>
                  <Box className={classes.direction}>
                    <Box className={classes.directionText}>
                      景品
                      <br /> 落下
                      <br /> 方向
                    </Box>
                    <img
                      src={
                        payoutInfoSupport.giftFallingDirection1[0] === COMMON.FLAG.ON ? arrowBottomOn : arrowBottomOff
                      }
                      alt="bottom"
                      className={classes.directionBottom}
                      width={34}
                      height={34}
                    />
                    <img
                      src={
                        payoutInfoSupport.giftFallingDirection1[1] === COMMON.FLAG.ON ? arrowBottomOn : arrowBottomOff
                      }
                      alt="bottomRight"
                      className={classes.directionBottomRight}
                      width={34}
                      height={34}
                    />
                    <img
                      src={
                        payoutInfoSupport.giftFallingDirection1[2] === COMMON.FLAG.ON ? arrowBottomOn : arrowBottomOff
                      }
                      alt="bottomLeft"
                      className={classes.directionBottomLeft}
                      width={34}
                      height={34}
                    />
                  </Box>
                  <img
                    src={payoutInfoSupport.timing1[0] === COMMON.FLAG.ON ? sideToggleSwapScreen : sideToggleSwapGray}
                    alt="catcherIconLateralMoving"
                    className="iconLateralMoving"
                  />
                  <img
                    src={payoutInfoSupport.timing1[1] === COMMON.FLAG.ON ? sideToggleSwapScreen : sideToggleSwapGray}
                    alt="catcherIcon0"
                    className="icon0"
                  />
                  <img
                    src={payoutInfoSupport.timing1[2] === COMMON.FLAG.ON ? sideToggleSwapScreen : sideToggleSwapGray}
                    alt="catcherIcon25"
                    className="icon25"
                  />
                  <img
                    src={payoutInfoSupport.timing1[3] === COMMON.FLAG.ON ? sideToggleSwapScreen : sideToggleSwapGray}
                    alt="catcherIcon50"
                    className="icon50"
                  />
                  <img
                    src={payoutInfoSupport.timing1[4] === COMMON.FLAG.ON ? sideToggleSwapScreen : sideToggleSwapGray}
                    alt="catcherIcon75"
                    className="icon75"
                  />
                  <img
                    src={payoutInfoSupport.timing1[5] === COMMON.FLAG.ON ? sideToggleSwapScreen : sideToggleSwapGray}
                    alt="catcherIcon100"
                    className="icon100"
                  />
                  <img src={arrowIconLeft} alt="arrowIconLeft" className="arrowIconLeft" />
                  <img src={arrowIconUp} alt="arrowIconUpTo0" className="arrowIconUpTo0" />
                  <img src={arrowIconUp} alt="arrowIconUpTo25" className="arrowIconUpTo25" />
                  <img src={arrowIconUp} alt="arrowIconUpTo50" className="arrowIconUpTo50" />
                  <img src={arrowIconUp} alt="arrowIconUpTo75" className="arrowIconUpTo75" />
                  <p className="labelLateralMoving">横移動</p>
                  <p className="label0">0</p>
                  <p className="label25">25</p>
                  <p className="label50">50</p>
                  <p className="label75">75</p>
                  <p className="label100">100</p>
                </Box>
              </Box>
            </Box>
          </Box>
        );
      }
      break;
    case COMMON.OPERATION_CONF.NEW_SERVICE_MODE:
      contentPaymentInfo = (
        <Box>
          <Table>
            <TableRow className={classes.operationConfNewServiceWrap}>
              <td className={`${classes.tableLabelWrap} ${classes.resetTimingLabel}`}>リセットタイミング</td>
              <td className={`${classes.tableValue} ${classes.resetTimingValue}`}>
                {getClassificationName(CLSFCN.RESET_TIMING.CATEGORY_CD, newServiceGraph.resetTiming)}
              </td>
            </TableRow>
          </Table>
          <Timing />
        </Box>
      );
      break;
    case COMMON.OPERATION_CONF.SHIFT_OPERATION:
      contentPaymentInfo = (
        <Box className={classes.shiftContent}>
          <Box className={classes.shiftOperatorLeftContent}>
            <Box className="contentBox">
              {getClassificationName(CLSFCN.SHIFT_CM.CATEGORY_CD, shiftOperation.shiftCm)}
            </Box>
          </Box>
          <Box className={classes.shiftOperatorRightContent}>
            <Box className={classes.shiftContainer}>
              <ShiftPanelReviewGrand shiftDirection={shiftOperation.shiftDirection} />
            </Box>
          </Box>
        </Box>
      );
      break;
    default:
      contentPaymentInfo = <></>;
      break;
  }

  return contentPaymentInfo;
};

const OperationConfGrand: React.FC<NewPayoutInfoSupportProps> = (props) => {
  const {
    title,
    isLeft,
    operationConf,
    payoutInfoSupport,
    newServiceGraph,
    shiftOperation,
    areaConf,
    savedConfProps,
  } = props;
  const classes = useStyles({ isLeft });
  const history = useHistory();
  let contentSubtitle = '';
  let operationConfBg = `${classes.operationConfBg}`;
  let operationConfBorder = '';
  let transitScreen = '';
  switch (operationConf) {
    case COMMON.OPERATION_CONF.NO_CONF:
      contentSubtitle = COMMON.OPERATION_TEXT['0'];
      operationConfBg = '';
      operationConfBorder = `${classes.border}`;
      break;
    case COMMON.OPERATION_CONF.NEW_PAYOUT_SUPPORT:
      contentSubtitle = COMMON.OPERATION_TEXT['1'];
      transitScreen = redirectLinks.MCONF_GRAND_CONF_NEW_PAYOUT_SUPPORT; // SCR333G
      break;
    case COMMON.OPERATION_CONF.NEW_SERVICE_MODE:
      contentSubtitle = COMMON.OPERATION_TEXT['2'];
      transitScreen = redirectLinks.MCONF_GRAND_CONF_NEW_SERVICE_MODE; // SCR334G
      break;
    case COMMON.OPERATION_CONF.SHIFT_OPERATION:
      contentSubtitle = COMMON.OPERATION_TEXT['3'];
      transitScreen = redirectLinks.MCONF_GRAND_CONF_SHIFT_OPERATION; // SCR332G
      break;
    default:
      contentSubtitle = '';
      break;
  }
  const cursorPoint = transitScreen ? `${classes.cursorPoint}` : '';
  const handleTransitScreen = () => transitScreen && history.push({ pathname: transitScreen, state: savedConfProps });
  return (
    <Box
      className={`${classes.container} ${operationConfBorder} ${classes.borderRadius} ${operationConfBg} ${cursorPoint}`}
      onClick={handleTransitScreen}>
      <Box className={classes.frame}>
        <Box className={`${classes.title} customTitle`}>
          {title}
          {contentSubtitle && <span>{contentSubtitle}</span>}
        </Box>
        {newPaymentInfoSupportContent(
          isLeft,
          operationConf,
          payoutInfoSupport,
          newServiceGraph,
          shiftOperation,
          areaConf,
        )}
      </Box>
    </Box>
  );
};

export default memo(OperationConfGrand);
