import React, { useCallback, useEffect, useState, useMemo } from 'react';
import Layout from 'components/system/layouts/Layout';
import MessageHelper from 'utils/message.helper';
import ScreenContext from 'Contexts/Common/ScreenContext';
import COMMON from 'constants/common';
import { Box, Grid, makeStyles } from '@material-ui/core';
import InfoDialog from 'components/system/organisms/dialogs/InfoDialog';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import DecisionButton from 'components/machineConf/atoms/DecisionButton';
import UrlHelper from 'utils/url.helper';
import MESSAGES from 'constants/messages';
import { NormalSelectBox } from 'components/system/organisms';
import { getClsfcnByCategory, getSortedClsfcnByCategory } from 'utils/common.helper';
import CLSFCN from 'constants/classification';
import PrevNextButton from 'components/system/atoms/buttons/PrevNextButton';
import { animateScroll as scroll } from 'react-scroll';
import { getUserAuthThunk, handleCallFrontError } from 'redux/slices/commonSlice';
import PeriodInputDialog from 'components/system/organisms/dialogs/PeriodInputDialog';
import HttpConnection from 'utils/httpConnection';
import { REST_API } from 'constants/apiUrls';
import TotalDataTable from 'components/data/organisms/tables/TotalDataTable';
import { TotalDataType } from 'types/data/dataType';
import ListCheckBox from 'components/system/atoms/checkBoxs/ListCheckBox';
import { getDecodedFilename } from 'utils/string.helper';

/* ************ Context ************ */
const ScreenContextValue = {
  title: 'トータルデータ閲覧',
  screenId: 'SCR211',
  currentMenu: COMMON.MENU.DATA,
  initializeState: true,
};

/* ********** Constants ********** */
const TABLE_ID = 'dataStationTable';
const MAX_DISPLAY_BAR = 7;
export const USE_REDUX_VALUES = {
  FALSE: '0', // Reduxの値を私用しない
  TRUE: '1', // Reduxの値を使用する
};

const millisecondsPerDay = 24 * 60 * 60 * 1000;
// 現在日
const toDateDefault = new Date().toISOString().split('T')[0];
// 現在日の30日前
const fromDateDefault = new Date(Date.now() - 30 * millisecondsPerDay).toISOString().split('T')[0];

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  contents: {
    padding: '12px 16px 4px 16px',
  },
  description: {
    padding: '9px 0 15px 24px',
    fontSize: '23px',
    lineHeight: '23px',
    marginBottom: 20,
  },
  sortArea: {
    display: 'flex',
    flexDirection: 'column',
    gap: 7,
    paddingLeft: '793px',
    paddingBottom: '10px',
    position: 'absolute',
    top: 0,
  },
  actionButton: {
    display: 'flex',
    textAlign: 'center',
    marginTop: '-15px',
    justifyContent: 'space-between',
  },
  decision: {
    display: 'flex',
    textAlign: 'center',
    width: '1100px',
    gap: 7,
    justifyContent: 'end',
  },
  previousBtn: {
    paddingBottom: '10px',
    position: 'inherit',
    textAlign: 'center',
    width: '71%',
    height: '29px',
  },
  preveNextButton: {
    position: 'absolute',
    right: 153,
  },
  nextBtn: {
    paddingTop: '5px',
    textAlign: 'center',
    height: '29px',
  },
  outerGrid: {
    position: 'relative',
  },
  checkAllType: {
    position: 'absolute',
    top: 130,
    fontSize: 15,
    left: 126,
  },
}));

/* ************ Type ************ */
type FilterDate = {
  fromDate: string;
  toDate: string;
};

const DataStationList: React.FC = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const loginUserInfo = useAppSelector((state) => state.common.userInfo);
  const dataSourceSort = getClsfcnByCategory(CLSFCN.DATA_STATION_SORT.CATEGORY_CD);
  const dataMachineDataSource = getSortedClsfcnByCategory(CLSFCN.MACHINE_TYPE_FILTER.CATEGORY_CD);
  const [checkAll, setCheckAll] = useState<boolean>(false);
  const { isPc } = useAppSelector((state) => state.common.deviceInfo);

  /* ************ state/redux ************ */
  const [totalDataList, setTotalDataList] = useState<Array<Array<TotalDataType>>>([]);
  const [totalDataListLength, setTotalDataListLength] = useState<number>(0);
  const [totalDataListSort, setTotalDataListSort] = useState<string>(CLSFCN.DATA_STATION_SORT.STATION_NAME_ASC);
  const [machineTypeFilter, setMachineTypeFilter] = useState<string>(CLSFCN.MACHINE_TYPE_FILTER.ALL);
  const [openInfoDialog, setOpenInfoDialog] = useState<boolean>(false);
  const [isOpenPeriodInputDialog, setIsOpenPeriodInputDialog] = useState(false);
  const [isOpenDataSheetPeriodInputDialog, setIsOpenDataSheetPeriodInputDialog] = useState(false);
  const [filterDate, setFilterDate] = useState<FilterDate>({ fromDate: fromDateDefault, toDate: toDateDefault });
  const [openDownloadCsvSuccess, setOpenDownloadCsvSuccess] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getUserAuthThunk());
  }, []);

  useEffect(() => {
    getStationTotalDataList().then((r) => r);
  }, [totalDataListSort, machineTypeFilter]);

  /* ************ Function ************ */
  const getStationTotalDataList = async () => {
    if (!loginUserInfo?.currentHeadQId || !loginUserInfo?.currentStoreId) {
      return;
    }
    const http = new HttpConnection({ dispatch });
    const data = await http.get<{
      totalDataList: Array<Array<TotalDataType>>;
    }>(
      UrlHelper.convertGetUrl(REST_API.DATA.GET_STATION_TOTAL_DATA_LIST, {
        machine_type: machineTypeFilter,
        sort: totalDataListSort,
      }),
    );
    setTotalDataList(data.totalDataList);
    const count = data.totalDataList.reduce((acc, subArray) => acc + subArray.length, 0);
    setTotalDataListLength(count);
  };

  /* ************ Event ************ */
  // sort change
  const handleChangeSort = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    setTotalDataListSort(ev.target.value);
  };

  const getIds = () => {
    const ids = totalDataList.reduce((prev: number[], cur) => {
      const id = cur.filter((item) => item.selected).map((s) => s.stationId);
      return [...prev, ...id];
    }, []);
    return ids;
  };

  const handleClickPrev = () => {
    scroll.scrollMore(-59, {
      duration: 59,
      delay: 0,
      containerId: TABLE_ID,
    });
  };

  const handleClickNext = () => {
    scroll.scrollMore(59, {
      duration: 59,
      delay: 0,
      containerId: TABLE_ID,
    });
  };

  const handleCloseInfoDialog = (): void => {
    setOpenInfoDialog(false);
  };

  // Handle change machine type sort
  const handleChangeMachineTypeSort = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    setMachineTypeFilter(ev.target.value);
  };

  const handleChangeSelectStation = useCallback(
    (boardSerial: string, stationId: number | null) => {
      const newTotalDataList = totalDataList.map((board) =>
        board.map((station) => {
          if (station.boardSerial === boardSerial && station.stationId === stationId) {
            // Create a new object with updated selected property
            return { ...station, selected: !station.selected };
          }
          return station;
        }),
      );
      setTotalDataList(newTotalDataList);
    },
    [totalDataList],
  );

  const handleCheckAll = useCallback(() => {
    setCheckAll(!checkAll);
    const newTotalDataList = totalDataList.map((board) =>
      board.map((station) =>
        // Create a new object with updated selected property
        ({ ...station, selected: !checkAll }),
      ),
    );
    setTotalDataList(newTotalDataList);
  }, [checkAll, totalDataList]);

  const handleClickTotalData = async () => {
    // 選択済みのｽﾃｰｼｮﾝIDを抽出
    const stIds = getIds();
    if (stIds.length === 0) {
      // 入力チェックメッセージ表示
      setOpenInfoDialog(true);
      return;
    }
    const http = new HttpConnection({ dispatch });
    const response = await http.postDownloadFileWithHeader(REST_API.DATA.TOTAL_DATASHEET, {
      station_id_list: stIds,
    });
    setOpenDownloadCsvSuccess(true);
    const fileName = getDecodedFilename(response.headers.contentDisposition);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  };

  // 日付のバリデーション
  const validateDate = () => {
    // （無効な日付はBEへ送付されないため）日付はFEでもバリデーション
    const messages = [];
    if (!filterDate.fromDate) {
      messages.push(MessageHelper.messageFormat(MESSAGES.INF114, '開始日'));
    }
    if (!filterDate.toDate) {
      messages.push(MessageHelper.messageFormat(MESSAGES.INF114, '終了日'));
    }
    if (messages.length > 0) {
      const errors = {
        errors: {
          key: messages,
        },
      };
      dispatch(handleCallFrontError(errors));
      return false;
    }
    return true;
  };

  // CSV発行ボタン
  const handleClickDecisionButton = async () => {
    if (!validateDate()) return;
    const stIds = getIds();

    if (stIds.length === 0) {
      // 入力チェックメッセージ表示
      setOpenInfoDialog(true);
      return;
    }

    const http = new HttpConnection({ dispatch });
    const response = await http.postDownloadFileWithHeader(REST_API.DATA.STATUS_DATASHEET, {
      start_date: filterDate.fromDate,
      end_date: filterDate.toDate,
      station_list: stIds,
    });
    setOpenDownloadCsvSuccess(true);
    const fileName = getDecodedFilename(response.headers.contentDisposition);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();

    // ダイアログ閉じる
    setIsOpenPeriodInputDialog(false);
    // 日付入力値クリア
    clearFilterDate();
  };

  // データシートの取得
  const getDatasheet = async (stIds: number[]) => {
    const http = new HttpConnection({ dispatch });
    const response = await http.postFileWithHeader(REST_API.DATA.DATASHEET, {
      fromDate: filterDate.fromDate,
      toDate: filterDate.toDate,
      stationIds: stIds,
    });

    const fileName = getDecodedFilename(response.headers.contentDisposition);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  };

  const handleClickGenerateDataSheetButton = async () => {
    if (!validateDate()) return;
    const stIds = getIds();

    if (stIds.length === 0) {
      // 入力チェックメッセージ表示
      setOpenInfoDialog(true);
      return;
    }

    // ファイル作成
    // ファイルダウンロード
    await getDatasheet(stIds);
    // ダイアログ閉じる
    setIsOpenDataSheetPeriodInputDialog(false);
    // 日付入力値クリア
    clearFilterDate();
    // ダウンロード成功メッセージ表示
    setOpenDownloadCsvSuccess(true);
  };

  // 日付入力値クリア
  const clearFilterDate = () => {
    setFilterDate({
      fromDate: fromDateDefault,
      toDate: toDateDefault,
    });
  };
  const handleChangeInput = (ev: React.ChangeEvent<HTMLInputElement>): void => {
    setFilterDate({
      ...filterDate,
      [ev.target.name]: ev.target.value,
    });
  };

  const canDownloadDataSheet: boolean = useMemo(() => {
    if (!isPc) return false;
    if (!loginUserInfo?.role) return false;
    if (![CLSFCN.ROLE.HEAD_ADMIN, CLSFCN.ROLE.HEAD_VIEWER, CLSFCN.ROLE.STORE_ADMIN].includes(loginUserInfo.role)) {
      return false;
    }
    return true;
  }, [loginUserInfo?.role, isPc]);

  const isBandaiNamcoUser: boolean = useMemo(() => {
    if (!loginUserInfo?.headquartersId) return false;
    if (![COMMON.HEADQUARTERS_ID.BNAM, COMMON.HEADQUARTERS_ID.BNAL].includes(loginUserInfo.headquartersId)) {
      return false;
    }
    return true;
  }, [loginUserInfo?.headquartersId]);

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <Box className={classes.contents}>
          <Grid container alignItems="flex-end" className={classes.outerGrid}>
            <Grid className={classes.description}>CSV出力するステーションを選択</Grid>
            <Grid className={classes.sortArea}>
              <NormalSelectBox
                name="sort"
                dataSource={dataMachineDataSource}
                value={machineTypeFilter}
                onChange={handleChangeMachineTypeSort}
                label="機種"
              />
              <NormalSelectBox
                name="sort"
                dataSource={dataSourceSort}
                value={totalDataListSort}
                onChange={handleChangeSort}
                label="並べ替え"
              />
            </Grid>
            <Grid className={classes.previousBtn}>
              {totalDataList.length > 0 && (
                <div className={classes.preveNextButton}>
                  <PrevNextButton isUp className="prevScroll" onClick={handleClickPrev} />
                </div>
              )}
            </Grid>
          </Grid>
          <div />
          <Box className={classes.checkAllType}>
            <ListCheckBox checked={checkAll} name="selectStation" onChange={handleCheckAll} />
            ステーション名全選択
          </Box>
          <TotalDataTable
            height={434}
            tableId={TABLE_ID}
            stationList={totalDataList}
            handleSelectedItemChange={handleChangeSelectStation}
          />
          <Grid className={classes.nextBtn}>
            {MAX_DISPLAY_BAR < totalDataListLength && (
              <PrevNextButton className="nextScroll" onClick={handleClickNext} />
            )}
          </Grid>
          <div className={classes.actionButton}>
            {canDownloadDataSheet && isBandaiNamcoUser && (
              <div>
                <DecisionButton onClick={() => setIsOpenDataSheetPeriodInputDialog(true)}>
                  データシート
                  <br />
                  発行
                </DecisionButton>
              </div>
            )}
            <Grid className={classes.decision}>
              {canDownloadDataSheet && isBandaiNamcoUser && (
                <DecisionButton onClick={() => setIsOpenPeriodInputDialog(true)}>
                  通信データ
                  <br />
                  CSV発行
                </DecisionButton>
              )}
              {canDownloadDataSheet && (
                <DecisionButton onClick={handleClickTotalData}>
                  トータルデータ
                  <br />
                  CSV発行
                </DecisionButton>
              )}
            </Grid>
          </div>
        </Box>
        <InfoDialog open={openInfoDialog} msg={MESSAGES.INF110} closeFunc={handleCloseInfoDialog} />
        <InfoDialog
          open={openDownloadCsvSuccess}
          msg={MESSAGES.INF310}
          closeFunc={() => setOpenDownloadCsvSuccess(false)}
        />
        <PeriodInputDialog
          open={isOpenPeriodInputDialog}
          msg={MessageHelper.messageFormat(MESSAGES.INF103, '出力する対象期間')}
          onOk={handleClickDecisionButton}
          onCancel={() => {
            setIsOpenPeriodInputDialog(false);
            clearFilterDate();
          }}
          filterDate={filterDate}
          onChangeDate={handleChangeInput}
        />
        <PeriodInputDialog
          open={isOpenDataSheetPeriodInputDialog}
          msg={MessageHelper.messageFormat(MESSAGES.INF103, '出力する対象期間')}
          onOk={handleClickGenerateDataSheetButton}
          onCancel={() => {
            setIsOpenDataSheetPeriodInputDialog(false);
            clearFilterDate();
          }}
          filterDate={filterDate}
          onChangeDate={handleChangeInput}
        />
      </Layout>
    </ScreenContext.Provider>
  );
};

export default DataStationList;
