import { makeStyles, SvgIcon } from '@material-ui/core';
import React, { FC, memo } from 'react';

type Props = {
  size?: number;
  color?: string;
  style?: {
    fontSize: string;
  };
};

const useStyles = makeStyles(() => ({
  root: {
    // アイコンサイズを指定, materiau-ui のrootを上書き
    fontSize: '32px',
  },
}));

const CheckedIcon: FC<Props> = (props) => {
  const { size } = props;
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.root }}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
        <g id="Group_468" data-name="Group 468" transform="translate(-289 -19.664)">
          <g id="Group_467" data-name="Group 467">
            <g
              id="_.target-image"
              data-name=".target-image"
              transform="translate(289 19.664)"
              fill="#fff"
              stroke="#5b5b5b"
              strokeLinejoin="round"
              strokeWidth="1">
              <rect width="32" height="32" rx="4" stroke="none" />
              <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" fill="none" />
            </g>
          </g>
          <g id="Group_465" data-name="Group 465" transform="translate(289 20.582)">
            <g
              id="チェックアイコン"
              transform="translate(1783.453 5954.717)"
              fill="#16c60c"
              strokeLinejoin="round"
              opacity="0.3">
              <path
                d="M -1770.570556640625 -5928.9443359375 L -1779.293579101562 -5937.97607421875 L -1775.69677734375 -5941.44970703125 L -1771.132568359375 -5936.7236328125 L -1770.785278320312 -5936.36376953125 L -1770.425659179688 -5936.7109375 L -1758.431640625 -5948.29345703125 L -1754.623291015625 -5944.3486328125 L -1766.61669921875 -5932.76708984375 L -1766.966186523438 -5932.42919921875 L -1766.96484375 -5932.427734375 L -1770.570556640625 -5928.9443359375 Z"
                stroke="none"
              />
              <path
                d="M -1770.558227539062 -5929.6513671875 L -1767.008544921875 -5933.0810546875 C -1766.994262695312 -5933.09619140625 -1766.9794921875 -5933.11181640625 -1766.964111328125 -5933.12646484375 L -1755.330322265625 -5944.361328125 L -1758.444091796875 -5947.58642578125 L -1770.078247070312 -5936.3515625 C -1770.475463867188 -5935.9677734375 -1771.108764648438 -5935.97900390625 -1771.492309570312 -5936.3759765625 L -1775.709106445312 -5940.74267578125 L -1778.586547851562 -5937.9638671875 L -1770.558227539062 -5929.6513671875 M -1770.582763671875 -5928.2373046875 L -1780.000610351562 -5937.98828125 L -1775.684448242188 -5942.15673828125 L -1770.77294921875 -5937.07080078125 L -1758.419311523438 -5949.00048828125 L -1753.916381835938 -5944.33642578125 L -1766.269409179688 -5932.4072265625 L -1766.26806640625 -5932.40576171875 L -1770.582763671875 -5928.2373046875 Z"
                stroke="none"
                fill="#5b5b5b"
              />
            </g>
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
};

export default memo(CheckedIcon);
