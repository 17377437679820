const CLSFCN = {
  // 並べ替え
  ALERT_SORT: {
    CATEGORY_CD: '001',
    ALL_NEW: '1',
    ALL_OLD: '2',
    STATION_NAME: '3',
    STATUS_NEW: '4',
    STATUS_PEND: '5',
    STATUS_NEW_OR_PEND: '6',
    STATUS_DONE: '7',
    SALES_DESC: '8',
    SALES_ASK: '9',
    OVERPAY: '10',
    UNDERPAY: '11',
    NO_PLAY_LONG: '12',
  },
  // 対応
  CORRESPOND_STATUS: {
    CATEGORY_CD: '002',
    NEW: '1',
    PEND: '2',
    DONE: '3',
    NATURAL_SOLVED: '4',
    PROCESSED: '5',
    ALERT_RESET: '6',
  },
  // 状態
  ALERT_STATUS: {
    CATEGORY_CD: '003',
    SUITABLE_PAY: '0',
    NO_PLAY_LONG: '1',
    HARD_ERROR: '2',
    NETWORK_ERROR: '3',
    ORVERPAY: '4',
    ORVERPAY_DONE: '5',
    UNDERPAY: '6',
  },
  // 落とし穴設定パターン
  PIT_PATTERN: {
    CATEGORY_CD: '004',
    SIMPLE: '0',
    DETAIL: '1',
    PINPOINT: '2',
  },
  // 押込力
  PUSHING: {
    CATEGORY_CD: '007',
    LOW: '0',
    MIDDLE: '1',
    HIGH: '2',
  },
  // レバー
  LEVER_TIME: {
    CATEGORY_CD: '008',
  },
  // 決定ボタン
  ENTER_BUTTON: {
    CATEGORY_CD: '009',
  },
  // 高さ
  HEIGHT: {
    CATEGORY_CD: '011',
  },
  // タイミング
  TIMING: {
    CATEGORY_CD: '012',
  },
  // ON OFF
  ON_OFF: {
    CATEGORY_CD: '014',
    ON: '1',
    OFF: '0',
  },
  GET_SUPPORT_CONF: {
    CATEGORY_CD: '067',
    ON_1: '2',
    ON_2: '3',
    OFF_1: '0',
    OFF_2: '1',
  },
  // 権限
  ROLE: {
    CATEGORY_CD: '015',
    SYSTEM_ADMIN: '00',
    HEAD_ADMIN: '10',
    HEAD_VIEWER: '11',
    STORE_ADMIN: '20',
    STORE_EDITOR: '21',
    STORE_VIEWER: '22',
  },
  // ユーザー一覧 -絞り込み条件
  USER_LIST_FILTER: {
    CATEGORY_CD: '016',
    ALL: '1',
    VALID: '2',
    INVALID: '3',
  },
  // 利用
  USER_VALID_DIV: {
    CATEGORY_CD: '017',
    VALID: '1',
    INVALID: '2',
  },
  // データ閲覧 -絞り込み条件
  DATA_VIEW_FILTER: {
    CATEGORY_CD: '018',
    STATION_ASC: '1',
    STATION_DESC: '2',
    SALES_ASC: '3',
    SALES_DESC: '4',
  },
  // キャッチャー速度設定
  CATCHER_SPEED: {
    CATEGORY_CD: '019',
  },
  // Home -時間別ボタン
  HOURLY_SALES_BTN: {
    CATEGORY_CD: '020',
    YESTERDAY: '0',
    12: '12',
    14: '14',
    16: '16',
    18: '18',
    20: '20',
    22: '22',
    24: '24',
  },
  // Settings Lever Operation
  SETTING_LEVER_OPEATION: {
    LEVER_TIME: '008',
    ENTER_BUTTON: '009',
  },
  // PIT CONFIG
  PIT_CONFIG: {
    PATTERN_CATEGORY_CD: '004',
    PATTERN: {
      SIMPLE: '0',
      DETAIL: '1',
      PINPOINT: '2',
    },
    STATUS_CATEGORY_CD: '005',
    STATUS: {
      ENABLED: '0',
      DISABLED: '1',
    },
  },
  PIT_CONFIG_JACK: {
    PATTERN_CATEGORY_CD: '053',
    PATTERN: {
      SIMPLE: '0',
      DETAIL: '1',
      PINPOINT: '2',
    },
    STATUS_CATEGORY_CD: '005',
    STATUS: {
      ENABLED: '0',
      DISABLED: '1',
    },
  },
  ARM_OPEN: {
    CATEGORY_CD: '006',
  },
  // AREA CONFIG
  AREA_CONFIG: {
    PUSHING_CATEGORY_CD: '007',
    PATTERN: {
      PATTERN_0: '0',
      PATTERN_1: '1',
      PATTERN_2: '2',
    },
    LINE: {
      SHOW: 1,
      LINE_1: '0',
      LINE_2: '1',
      LINE_3: '2',
      LINE_4: '3',
      LINE_5: '4',
      LINE_6: '5',
    },
    PIT: {
      SHOW: '1',
      LINE_1: '0',
      LINE_2: '1',
      LINE_3: '2',
      LINE_4: '3',
      LINE_5: '4',
      LINE_6: '5',
      STATUS: '0',
    },
    BUTTON: {
      BUTTON_0: '0',
      BUTTON_1: '1',
      BUTTON_2: '2',
      BUTTON_3: '3',
      BUTTON_4: '4',
      BUTTON_5: '5',
    },
  },
  // speed conf
  SPEED_CONF: {
    XYZ: '019',
  },
  // データ-ｽﾃｰｼｮﾝ一覧 sort
  DATA_STATION_SORT: {
    CATEGORY_CD: '021',
    STATION_NAME_ASC: '1',
  },
  // データ-原価率データ-sort
  DATA_VIEW_CR_SORT: {
    CATEGORY_CD: '022',
    STATION_NAME_ASC: '1',
    STATION_NAME_DESC: '2',
    SORT_CR_ASC: '3',
    SORT_CR_DESC: '4',
  },
  // パスワード強制変更フラグ
  PW_CHANGE_FLG: {
    CATEGORY_CD: '023',
    FALSE: '0',
    TRUE: '1',
  },
  DATA_EQUIPMENT_SORT: {
    CATEGORY_CD: '024',
    EQUIPMENT_SORT_DEFAULT: '1',
  },
  // 新サービスモード リセットタイミング
  RESET_TIMING: {
    CATEGORY_CD: '024',
    GET_GIFT: '0', // 景品獲得時
    END_CONTINUOUS_PLAY: '1', // 連続プレイ終了時
  },
  // 新サービスモード プレイ回数
  PLAY_COUNT: {
    CATEGORY_CD: '025',
  },
  // 新サービスモード 上限プレイ回数
  LIMIT_PLAY_COUNT: {
    CATEGORY_CD: '026',
  },
  // ずらし運営設定 設定パターン
  SHIFT_PATTERN: {
    CATEGORY_CD: '027',
    FRONT_SHIFT: '0', // 手前ずらし
    RIGHT_SHIFT: '1', // 右ずらし
    LEFT_SHIFT: '2', // 左ずらし
    CENTER_HORIZONTAL_SHIFT: '3', // 中央ずらし（横）
    CENTER_VERTICAL_SHIFT: '4', // 中央ずらし（縦）
    CENTER_SHIFT: '5', // 中心ずらし
    BACK_SHIFT: '6', // 奥ずらし
    CUSTOM_SHIFT: '7', // カスタム
  },
  // ずらし運営設定 cm選択
  SHIFT_CM: {
    CATEGORY_CD: '028',
  },
  // 照明演出設定 色
  LED_COLOR: {
    CATEGORY_CD: '029',
    BASE_COLOR: '0',
  },
  // 照明演出設定 効果
  LED_EFFECT: {
    CATEGORY_CD: '030',
    NONE_LIGHTING: '0', // 無し（点灯）
    WHITE_SLOW: '1', // 白変化（ゆっくり）
  },
  LED_EFFECT_GRAND: {
    CATEGORY_CD: '068',
    NONE_LIGHTING: '0', // 無し（点灯）
    WHITE_SLOW: '1', // 白変化（ゆっくり）
  },
  // 照明演出設定 設定内容
  LED_SETTING: {
    CATEGORY_CD: '031',
    SIMPLE: '0',
    CUSTOM: '1',
  },
  // 照明演出設定 演出内容
  LED_DIRECTING: {
    CATEGORY_CD: '032',
    MIX1: '3',
  },
  // その他設定 ステーション停止
  STATION_STOP: {
    CATEGORY_CD: '033',
  },
  DATA_GIFT_LIST_SORT: {
    CATEGORY_CD: '045',
    GIFT_LIST_SORT_DEFAULT: '0',
  },
  // グループ 機器選択
  MACHINE_SORT: {
    CATEGORY_CD: '034',
    BOARD_SERIAL_ASK: '1',
    UNREGISTERED_PRIORITY: '5',
    REGISTERED_PRIORITY: '6',
  },
  // 料金サービス設定 料金設定
  PRICING: {
    CATEGORY_CD: '035',
  },
  // 料金サービス設定 目標景品原価率
  TARGET_COST_RATE: {
    CATEGORY_CD: '036',
  },
  // 料金サービス設定 とり放題
  FREE_GET: {
    CATEGORY_CD: '037',
  },
  // 料金サービス設定 台キープ時間
  KEEP_TIME: {
    CATEGORY_CD: '038',
  },
  KEEP_TIME_GRAND: {
    CATEGORY_CD: '069',
  },
  // 料金サービス設定 連続プレイみなし時間
  PLAYING_DEEMED_TIME: {
    CATEGORY_CD: '039',
  },
  PLAYING_DEEMED_TIME_GRAND: {
    CATEGORY_CD: '070',
  },
  // 通信設定 (SYNCの値を変更する場合、LedOrderSetting.tsxの処理を必ず更新すること)
  GROUP_COMMUNICATION_CONF: {
    CATEGORY_CD: '040',
    SYNC: '1',
  },
  // BGM
  GROUP_BGM: {
    CATEGORY_CD: '041',
    BGM1: '0',
  },
  // SE
  GROUP_SE: {
    CATEGORY_CD: '042',
    SET1: '0',
  },
  // VOICE
  GROUP_VOICE: {
    CATEGORY_CD: '043',
    SET1: '0',
  },
  GROUP_SORT: {
    CATEGORY_CD: '044',
    LASTEST_ORDER: '0',
  },
  SORT_STATION_LIST: {
    CATEGORY_CD: '046',
    STATION_NAME_ASC: '0',
  },
  ARM_SIZE: {
    CATEGORY_CD: '047',
    NONE: '0',
    SMALL: '1',
    MEDIUM: '2',
    LARGE: '3',
  },
  SHIFT_PATTERN_NAME: {
    CATEGORY_CD: '048',
  },
  NEW_PAYOUT_TARGET_COST_RATE: {
    CATEGORY_CD: '049',
  },
  // 照明演出設定 エコモード
  LED_ECO_MODE: {
    CATEGORY_CD: '051',
    OFF: '1',
    ON: '0',
  },
  // 照明演出設定 エコモード(ジャック)
  LED_ECO_TYPE: {
    CATEGORY_CD: '052',
    OFF: '0',
    ECO1: '1',
  },
  MACHINE_ENTRY_STATUS: {
    COMPLETED: '0',
    TIME_OUT_ERROR: '1',
    CANCELED: '2',
    SENDING: '3',
    DUPLICATED: '4',
    SEND_ERROR: '5',
  },
  CATCHER_DIRECTION: {
    CATEGORY_CD: '050',
  },
  MACHINE_TYPE_FILTER: {
    CATEGORY_CD: '054',
    ALL: '99',
  },
  MACHINE_TYPE_STATION_SELECT_FILTER: {
    CATEGORY_CD: '055',
    CLENA3: '0',
  },
  LED_GRAND_COLOR: {
    CATEGORY_CD: '056',
    OFF: '99',
    SAMPLE_PATTERN: '0',
  },
  ARM_CONDITION: {
    CATEGORY_CD: '058',
  },
  CATCHER_ANGEL: {
    CATEGORY_CD: '059',
  },
  PRICING_NEW: {
    CATEGORY_CD: '063',
  },
  SERVICE_BUTTON_SETTING: {
    CATEGORY_CD: '064',
  },
  VOICE_MACHINE_TYPE_FILTER: {
    CATEGORY_CD: '096',
    ALL: '99',
  },
};

export default CLSFCN;
