import React from 'react';
import redirectLinks from 'constants/redirectLinks';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Login from 'pages/login/Login';
import PasswordConf from 'pages/login/PasswordConf';
import Top from 'pages/home/Top';
import StationSelect from 'pages/machineConf/StationSelect';
import StationConfTop from 'pages/machineConf/StationConfTop';
import DataStationList from 'pages/data/DataStationList';
import GroupConfTop from 'pages/group/GroupConfTop';
import GroupSelectCategory from 'pages/group/GroupSelectCategory';
import GroupListData from 'pages/group/GroupListData';
import GetGiftOrderSetting from 'pages/group/GetGiftOrderSetting';
import LedSelectMachine from 'pages/group/LedSelectMachine';
import LedGroupCreate from 'pages/group/LedGroupCreate';
import LedOrderSetting from 'pages/group/LedOrderSetting';
import MaintenaceTop from 'pages/maintenance/MaintenaceTop';
import UserList from 'pages/maintenance/UserList';
import UserDetail from 'pages/maintenance/UserDetail';
import SystemError from 'pages/common/SystemError';
import GameConfTop from 'pages/machineConf/GameConfTop';
import XyPitAreaTop from 'pages/machineConf/XyPitAreaTop';
import LeverOperationConf from 'pages/machineConf/LeverOperationConf';
import NewPayoutSupportConf from 'pages/machineConf/NewPayoutSupportConf';
import AreaPowerConf from 'pages/machineConf/AreaPowerConf';
import AreaConf from 'pages/machineConf/AreaConf';
import XyRangeConf from 'pages/machineConf/XyRangeConf';
import PitConf from 'pages/machineConf/PitConf';
import SessionTimeoutError from 'pages/common/SessionTimeoutError';
import StationHomeDetail from 'pages/machineConf/StationHomeDetail';
import Filter from 'filters/Filter';
import AuthCheck from 'pages/common/AuthCheck';
import OtherConf from 'pages/machineConf/OtherConf';
import SavedConf from 'pages/machineConf/SavedConf';
import GiftList from 'pages/maintenance/GiftList';
import CostServiceConf from 'pages/machineConf/CostServiceConf';
import GiftDetail from 'pages/maintenance/GiftDetail';
import MachineList from 'pages/maintenance/MachineList';
import MachineDetail from 'pages/maintenance/MachineDetail';
import NewServiceModeConf from 'pages/machineConf/NewServiceModeConf';
import GetGiftGroupCreate from 'pages/group/GetGiftGroupCreate';
import SequenceConf from 'pages/machineConf/SequenceConf';
import CatcherSpeedConf from 'pages/machineConf/CatcherSpeedConf';
import ShiftOperation from 'pages/machineConf/ShiftOperation';
import LedConf from 'pages/machineConf/LedConf';
import GetGiftSelectMachine from 'pages/group/GetGiftSelectMachine';
import RegisterGiftDestinationList from 'pages/maintenance/RegisterGiftDestinationList';
import StationConfTopJack from 'pages/machineConf/StationConfTopJack';
import GameConfTopJack from 'pages/machineConf/GameConfTopJack';
import XyPitAreaTopJack from 'pages/machineConf/XyPitAreaTopJack';
import CatcherSpeedConfJack from 'pages/machineConf/CatcherSpeedConfJack';
import LeverOperationConfJack from 'pages/machineConf/LeverOperationConfJack';
import StationHomeDetailJack from 'pages/machineConf/StationHomeDetailJack';
import LedConfJack from 'pages/machineConf/LedConfJack';
import AreaPowerConfJack from 'pages/machineConf/AreaPowerConfJack';
import PitConfJack from 'pages/machineConf/PitConfJack';
import OtherConfJack from 'pages/machineConf/OtherConfJack';
import XyRangeConfJack from 'pages/machineConf/XyRangeConfJack';
import CostServiceConfJack from 'pages/machineConf/CostServiceConfJack';
import AreaConfJack from 'pages/machineConf/AreaConfJack';
import ShiftOperationJack from 'pages/machineConf/ShiftOperationJack';
import NewPayoutSupportConfJack from 'pages/machineConf/NewPayoutSupportConfJack';
import NewServiceModeConfJack from 'pages/machineConf/NewServiceModeConfJack';
import SequenceConfJack from 'pages/machineConf/SequenceConfJack';
import OperationExampleList from 'pages/manualNotice/OperationExampleList';
import OperationExamplePdf from 'pages/manualNotice/OperationExamplePdf';
import OtherConfGrand from 'pages/machineConf/OtherConfGrand';
import SequenceConfGrand from 'pages/machineConf/SequenceConfGrand';
import VersionChecking from 'components/system/organisms/VersionChecking';
import PitConfGrand from 'pages/machineConf/PitConfGrand';
import ShiftOperationGrand from 'pages/machineConf/ShiftOperationGrand';
import SupportPartitionGrand from 'pages/machineConf/SupportPartitionGrand';
import NewServiceModeConfGrand from 'pages/machineConf/NewServiceModeConfGrand';
import MessageConfGrand from 'pages/machineConf/MessageConfGrand';
import StationHomeDetailGrand from 'pages/machineConf/StationHomeDetailGrand';
import NewPayoutSupportConfGrand from 'pages/machineConf/NewPayoutSupportConfGrand';
import LedConfGrand from 'pages/machineConf/LedConfGrand';
import XyRangeConfGrand from 'pages/machineConf/XyRangeConfGrand';
import AreaConfGrand from 'pages/machineConf/AreaConfGrand';
import VoiceList from '../pages/voice/VoiceList';
import VoiceStationSelect from '../pages/voice/VoiceStationSelect';

const filterRoutes = [
  // HOME
  { path: redirectLinks.HOME_TOP, comp: <Top /> },
  // データ
  { path: redirectLinks.DATA_STATION_LIST, comp: <DataStationList /> },
  // 機器設定（clena3）
  { path: redirectLinks.MCONF_STATION_SELECT, comp: <StationSelect /> },
  { path: redirectLinks.MCONF_STATION_CONF_TOP, comp: <StationConfTop /> },
  { path: redirectLinks.MCONF_STATION_CONF_XY_PIT_AREA_TOP, comp: <XyPitAreaTop /> },
  { path: redirectLinks.MCONF_STATION_CONF_PIT_CONF, comp: <PitConf /> },
  { path: redirectLinks.MCONF_STATION_CONF_XY_RANGE_CONF, comp: <XyRangeConf /> },
  { path: redirectLinks.MCONF_STATION_CONF_CATCHER_SPEED_CONF, comp: <CatcherSpeedConf /> },
  { path: redirectLinks.MCONF_STATION_CONF_AREA_POWER_CONF, comp: <AreaPowerConf /> },
  { path: redirectLinks.MCONF_STATION_CONF_AREA_CONF, comp: <AreaConf /> },
  { path: redirectLinks.MCONF_STATION_CONF_LEVER_OPERATION, comp: <LeverOperationConf /> },
  { path: redirectLinks.MCONF_STATION_CONF_GAME_CONF_TOP, comp: <GameConfTop /> },
  { path: redirectLinks.MCONF_STATION_CONF_NEW_PAYOUT_SUPPORT, comp: <NewPayoutSupportConf /> },
  { path: redirectLinks.MCONF_STATION_CONF_NEW_SERVICE_MODE, comp: <NewServiceModeConf /> },
  { path: redirectLinks.MCONF_STATION_CONF_SHIFT_OPERATION, comp: <ShiftOperation /> },
  { path: redirectLinks.MCONF_STATION_CONF_SEQUENCE, comp: <SequenceConf /> },
  { path: redirectLinks.MCONF_STATION_CONF_LED, comp: <LedConf /> },
  { path: redirectLinks.MCONF_STATION_CONF_COST_SERVICE, comp: <CostServiceConf /> },
  { path: redirectLinks.MCONF_STATION_CONF_OTHER, comp: <OtherConf /> },
  { path: redirectLinks.MCONF_STATION_CONF_SAVED_CONF, comp: <SavedConf /> },
  { path: redirectLinks.MCONF_STATION_HOME_DETAIL, comp: <StationHomeDetail /> },
  // 機器設定（clena3ジャック）
  { path: redirectLinks.MCONF_JACK_CONF_TOP, comp: <StationConfTopJack /> },
  { path: redirectLinks.MCONF_JACK_CONF_XY_PIT_AREA_TOP, comp: <XyPitAreaTopJack /> },
  { path: redirectLinks.MCONF_JACK_CONF_CATCHER_SPEED_CONF, comp: <CatcherSpeedConfJack /> },
  { path: redirectLinks.MCONF_JACK_CONF_LEVER_OPERATION, comp: <LeverOperationConfJack /> },
  { path: redirectLinks.MCONF_JACK_CONF_LED, comp: <LedConfJack /> },
  { path: redirectLinks.MCONF_JACK_CONF_GAME_CONF_TOP, comp: <GameConfTopJack /> },
  { path: redirectLinks.MCONF_JACK_CONF_NEW_PAYOUT_SUPPORT, comp: <NewPayoutSupportConfJack /> },
  { path: redirectLinks.MCONF_JACK_CONF_NEW_SERVICE_MODE, comp: <NewServiceModeConfJack /> },
  { path: redirectLinks.MCONF_JACK_CONF_SHIFT_OPERATION, comp: <ShiftOperationJack /> },
  { path: redirectLinks.MCONF_JACK_CONF_SEQUENCE, comp: <SequenceConfJack /> },
  { path: redirectLinks.MCONF_JACK_CONF_COST_SERVICE, comp: <CostServiceConfJack /> },
  { path: redirectLinks.MCONF_JACK_HOME_DETAIL, comp: <StationHomeDetailJack /> },
  { path: redirectLinks.MCONF_JACK_CONF_PIT_CONF, comp: <PitConfJack /> },
  { path: redirectLinks.MCONF_JACK_CONF_AREA_POWER_CONF, comp: <AreaPowerConfJack /> },
  { path: redirectLinks.MCONF_JACK_CONF_OTHER, comp: <OtherConfJack /> },
  { path: redirectLinks.MCONF_JACK_CONF_XY_RANGE_CONF, comp: <XyRangeConfJack /> },
  { path: redirectLinks.MCONF_JACK_CONF_AREA_CONF, comp: <AreaConfJack /> },
  // grand
  { path: redirectLinks.MCONF_GRAND_CONF_LED, comp: <LedConfGrand /> },
  { path: redirectLinks.MCONF_GRAND_CONF_PIT_CONF, comp: <PitConfGrand /> },
  { path: redirectLinks.MCONF_GRAND_CONF_XY_RANGE_CONF, comp: <XyRangeConfGrand /> },
  { path: redirectLinks.MCONF_GRAND_CONF_AREA_CONF, comp: <AreaConfGrand /> },
  { path: redirectLinks.MCONF_GRAND_HOME_DETAIL, comp: <StationHomeDetailGrand /> },
  { path: redirectLinks.MCONF_GRAND_CONF_NEW_SERVICE_MODE, comp: <NewServiceModeConfGrand /> },
  { path: redirectLinks.MCONF_GRAND_MESSAGE, comp: <MessageConfGrand /> },
  { path: redirectLinks.MCONF_GRAND_SUPPORT_PARTITION, comp: <SupportPartitionGrand /> },
  { path: redirectLinks.MCONF_GRAND_CONF_SHIFT_OPERATION, comp: <ShiftOperationGrand /> },
  { path: redirectLinks.MCONF_GRAND_CONF_SEQUENCE, comp: <SequenceConfGrand /> },
  { path: redirectLinks.MCONF_GRAND_CONF_OTHER, comp: <OtherConfGrand /> },
  { path: redirectLinks.MCONF_GRAND_CONF_NEW_PAYOUT_SUPPORT, comp: <NewPayoutSupportConfGrand /> },

  // グループ
  { path: redirectLinks.GROUP_CONF_TOP, comp: <GroupConfTop /> },
  { path: redirectLinks.GROUP_SELECT_CONF, comp: <GroupSelectCategory /> },
  { path: redirectLinks.GROUP_LIST_DATA, comp: <GroupListData /> },
  { path: redirectLinks.GROUP_LIST, comp: <GroupConfTop /> },
  { path: redirectLinks.GROUP_LED_CONF, comp: <GroupConfTop /> },
  { path: redirectLinks.GROUP_GET_GIFT_GROUP_CREATE, comp: <GetGiftGroupCreate /> },
  { path: redirectLinks.GROUP_GET_GIFT_SELECT_REGISTER_MACHINE, comp: <GetGiftSelectMachine /> },
  { path: redirectLinks.GROUP_GET_GIFT_INTERLOCKING_ORDER_SETTING, comp: <GetGiftOrderSetting /> },
  { path: redirectLinks.GROUP_LED_GROUP_CREATE, comp: <LedGroupCreate /> },
  { path: redirectLinks.GROUP_LED_SELECT_REGISTER_MACHINE, comp: <LedSelectMachine /> },
  { path: redirectLinks.GROUP_LED_INTERLOCKING_ORDER_SETTING, comp: <LedOrderSetting /> },
  // 管理登録
  { path: redirectLinks.MAINT_TOP, comp: <MaintenaceTop /> },
  { path: redirectLinks.MAINT_MACHINE_LIST, comp: <MachineList /> },
  { path: redirectLinks.MAINT_USER_LIST, comp: <UserList /> },
  { path: redirectLinks.MAINT_USER_DETAIL_NEW, comp: <UserDetail /> },
  { path: redirectLinks.MAINT_USER_DETAIL_EDIT, comp: <UserDetail /> },
  { path: redirectLinks.MAINT_MACHINE_DETAIL, comp: <MachineDetail /> },
  { path: redirectLinks.MAINT_GIFT_LIST, comp: <GiftList /> },
  { path: redirectLinks.GIFT_DETAIL, comp: <GiftDetail /> },
  { path: redirectLinks.SELECT_STATION_LIST_FOR_GIFT_REGISTER, comp: <RegisterGiftDestinationList /> },

  // Manual notice
  { path: redirectLinks.OPERATION_EXAMPLE_LIST, comp: <OperationExampleList /> },

  // Voice list
  { path: redirectLinks.VOICE_LIST, comp: <VoiceList /> },
  { path: redirectLinks.VOICE_SELECT_STATION, comp: <VoiceStationSelect /> },
];

const Router: React.FC = () => (
  <BrowserRouter>
    <Switch>
      {/* <Route exact path="/" component={Login} /> */}
      <Route exact path={redirectLinks.LOGIN} component={Login} />
      <Route exact path={redirectLinks.PW_CONF} component={PasswordConf} />
      {/* 共通画面 */}
      <Route exact path={redirectLinks.COMMON_SYSTEM_ERROR} component={SystemError} />
      <Route exact path={redirectLinks.COMMON_SESSION_TIMEOUT_ERROR} component={SessionTimeoutError} />
      <Route exact path={redirectLinks.COMMON_AUTH_CHECK} component={AuthCheck} />
      {/*  テスト用 コメントアウトし、ローカル環境で必要な時のみ利用する。
      <Route exact path="/test" component={TestAPI} />
      */}
      {/* PDF画面 */}
      <Route path={redirectLinks.OPERATION_EXAMPLE_PDF} component={OperationExamplePdf} />
      {/* フィルタ対象の画面 上部のfilterRouteで定義 */}
      {filterRoutes.map((filterRoute) => (
        <Route exact path={filterRoute.path} render={() => <Filter>{filterRoute.comp}</Filter>} />
      ))}
      <Route exact path="*" component={Login} />
    </Switch>
    <VersionChecking />
  </BrowserRouter>
);

export default Router;
