import React, { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles, Box } from '@material-ui/core';
import redirectLinks from 'constants/redirectLinks';
import ScreenContext from 'Contexts/Common/ScreenContext';
import COMMON from 'constants/common';
import CLSFCN from 'constants/classification';
import MSG from 'constants/messages';
import { UserInfoType } from 'types/common/commonType';
import { UserInfoType as UserDetailInfoType } from 'types/maintenance/UserInfoType';
import UrlHelper from 'utils/url.helper';
import { getClsfcnByCategory } from 'utils/common.helper';
import MsgHelper from 'utils/message.helper';
import { formatDatetime, formatTime } from 'utils/datetime.helper';
import { IS_FILTERED } from 'pages/maintenance/UserList';
import Layout from 'components/system/layouts/Layout';
import ReturnButton from 'components/system/atoms/buttons/ReturnButton';
import NomalButton from 'components/system/atoms/buttons/NormalButton';
import SelectBoxWithLabel from 'components/system/atoms/selectBoxs/SelectBoxWithLabel';
import ConfirmDialog from 'components/system/organisms/dialogs/ConfirmDialog';
import InfoDialog from 'components/system/organisms/dialogs/InfoDialog';
import DoneButton from 'components/system/atoms/buttons/DoneButton';
import DeleteButton from 'components/system/atoms/buttons/DeleteButton';
import OutlineTextBox from 'components/system/atoms/textBoxs/OutlineTextBox';
import RadioButtons, { RadioType } from 'components/system/atoms/radioButtons/RadioButton';
import BorderBottomBox from 'components/system/atoms/boxs/BorderBottomBox';
import HttpConnection from 'utils/httpConnection';
import { REST_API } from 'constants/apiUrls';

/* ************ Context ************ */
const ScreenContextValue = {
  title: 'ユーザー登録',
  screenId: 'SCR532',
  currentMenu: COMMON.MENU.MAINTENANCE,
};

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  contens: {
    padding: '10px 16px',
    '& .topArea': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      padding: '0 60px 0 0',
    },
    '& .mainArea': {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      padding: '20px 60px 20px 0',
      '& .leftArea': {
        width: '692px',
        padding: '18px 20px 0 0',
      },
      '& .rightArea': {
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'column',
        width: '460px',
      },
    },
    '& .bottomArea': {
      display: 'flex',
      alignSelf: 'flex-end',
      justifyContent: 'space-between',
    },
  },
  returnBtn: {
    display: 'flex',
  },
  unLockBtn: {
    margin: '20px 0 0 300px',
  },
  lastLoginTime: {
    height: '57px',
    maxWidth: '470px',
    fontSize: '24px',
    lineHeight: '28px',
    '& .label': {
      width: '145px',
      margin: '0 40px 0 0 ',
    },
  },
  accountLock: {
    margin: '38px 0 0 0',
    height: '57px',
    maxWidth: '470px',
    fontSize: '24px',
    lineHeight: '28px',
    '& .label': {
      width: '145px',
      margin: '0 40px 0 0 ',
    },
    '& .value': {
      color: '#FF0303',
    },
  },
  registBtn: {
    width: '200px',
    height: '72px',
    fontSize: '28px',
    margin: '0 0 0 auto',
  },
  storeName: {
    '& .outlinedInput': {
      width: '550px',
    },
  },
  accountId: {
    margin: '20px 0 0 0',
  },
  password: {
    margin: '5px 0 0 0',
  },
  validDiv: {
    margin: '5px 0 0 0',
  },
  role: {
    marginTop: '5px',
  },
  contentText: { fontSize: '24px' },
}));

/* ************ Constant ************ */
// messages
const msgParam = 'ユーザー';
const infoMsgRegist: string = MsgHelper.messageFormat(MSG.INF302, msgParam);
const infoMsgUpdate: string = MsgHelper.messageFormat(MSG.INF303, msgParam);
const infoMsgDelete: string = MsgHelper.messageFormat(MSG.INF304, msgParam);
const infoMsgUnlock: string = MsgHelper.messageFormat(MSG.INF305, 'アカウントロック');
const confirmMsgUpdate: string = MsgHelper.messageFormat(MSG.INF215, msgParam);
const confirmMsgDelete: string = MsgHelper.messageFormat(MSG.INF216, msgParam);
// 画面項目制御用定数
const SRC_MODE = {
  NEW: 0, // 新規作成画面
  EDIT: 1, // 編集画面
  REFER: 2, // 参照画面
};

/* ************ Function ************ */
// 利用の選択肢
const validDivRadios = (): Array<RadioType> =>
  getClsfcnByCategory(CLSFCN.USER_VALID_DIV.CATEGORY_CD).map((cls) => ({
    value: cls.value,
    label: cls.name,
  }));
// ログインユーザの権限によって権限の選択肢を変更
const getRoleList = (loginUserRole: string): Array<string> => {
  const list: Array<string> = [];
  if (loginUserRole === CLSFCN.ROLE.STORE_VIEWER || loginUserRole === CLSFCN.ROLE.HEAD_VIEWER) {
    return list;
  }
  list.push(CLSFCN.ROLE.STORE_VIEWER);
  if (loginUserRole === CLSFCN.ROLE.STORE_EDITOR) {
    return list;
  }
  list.push(CLSFCN.ROLE.STORE_EDITOR);
  if (loginUserRole === CLSFCN.ROLE.STORE_ADMIN) {
    return list;
  }
  list.push(CLSFCN.ROLE.STORE_ADMIN);
  list.push(CLSFCN.ROLE.HEAD_VIEWER);
  if (loginUserRole === CLSFCN.ROLE.HEAD_ADMIN) {
    return list;
  }
  if (loginUserRole === CLSFCN.ROLE.SYSTEM_ADMIN) {
    return [CLSFCN.ROLE.HEAD_ADMIN];
  }
  return [];
};
// 権限の選択肢
const roleDatasource = (loginUserRole?: string) => {
  if (loginUserRole === undefined) {
    return [];
  }
  const roleList = getRoleList(loginUserRole);
  return getClsfcnByCategory(CLSFCN.ROLE.CATEGORY_CD).filter((cls) => roleList.includes(cls.value));
};
// 本部名/店舗名の取得
const getStoreName = (loginUserInfo: UserInfoType | null): string => {
  if (loginUserInfo?.currentHeadQId === loginUserInfo?.currentStoreId) {
    return `${loginUserInfo?.currentHeadQName}`;
  }
  return `${loginUserInfo?.currentHeadQName} ${loginUserInfo?.currentStoreName}`;
};

/* ************ Component ************ */
const UserDetail: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  /* ************ state/redux ************ */
  const loginUserInfo = useAppSelector((state) => state.common.userInfo);
  const userIdLogin = loginUserInfo?.userId;
  const { userId: targetUserId } = useParams<{ userId?: string }>();
  // システム項目
  const [srcMode, setSrcMode] = useState<number>(SRC_MODE.REFER);
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const [isEditYourSelf] = useState<boolean>(userIdLogin?.toString() === targetUserId?.toString());
  const accountLockTerm = useRef<number>(0);
  // 画面項目用
  const [inputValue, setInputValue] = useState<{
    userId?: string;
    versionNo: number;
    accountId: string;
    password: string;
    newPassword: string;
    validDiv: string | undefined;
    role: string;
    rockTimestamp?: string;
    lastLoginTimestamp?: string;
  }>({
    userId: targetUserId,
    versionNo: 0,
    accountId: '',
    password: '',
    newPassword: '',
    validDiv: CLSFCN.USER_VALID_DIV.VALID,
    role: loginUserInfo?.role === CLSFCN.ROLE.SYSTEM_ADMIN ? CLSFCN.ROLE.HEAD_ADMIN : CLSFCN.ROLE.STORE_VIEWER,
  });
  // ダイアログ制御用
  const [openUpdateConfirmDlg, setOpenUpdateConfirmDlg] = useState(false);
  const [openDeleteConfirmDlg, setOpenDeleteConfirmDlg] = useState(false);
  const [openDeleteInfoDlg, setOpenDeleteInfoDlg] = useState(false);
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const [infoDlgMsg, setInfoDlgMsg] = useState('');

  /* ************ Local Function ************ */
  useEffect(() => {
    if (inputValue.userId === undefined) {
      return; // 新規登録画面
    }
    // 編集/参照画面の場合、ユーザ情報を取得
    getUserDetailApi();
  }, []);

  // 画面項目の制御
  useEffect(() => {
    if (inputValue.userId === undefined) {
      setSrcMode(SRC_MODE.NEW); // 新規ユーザ登録
      return;
    }
    if (canEdit) {
      setSrcMode(SRC_MODE.EDIT); // ユーザ情報編集可
      return;
    }
    setSrcMode(SRC_MODE.REFER); // ユーザ情報編集不可
  }, [canEdit]);

  /* ************ API ************ */
  const getUserDetailApi = async () => {
    if (inputValue.userId === undefined) {
      return;
    }
    const http = new HttpConnection({ dispatch });
    const { canEdit: tmpCanEdit, userInfo } = await http.get<{
      canEdit: boolean;
      userInfo: UserDetailInfoType | undefined;
    }>(UrlHelper.convertQueryUrl(REST_API.MAINTENANCE.GET_USER_INFO_DETAIL, { userId: inputValue.userId }));
    setCanEdit(tmpCanEdit);
    if (userInfo?.accountLockTerm) accountLockTerm.current = userInfo.accountLockTerm;
    setInputValue({
      ...inputValue,
      userId: userInfo?.userId,
      versionNo: userInfo?.versionNo || 0,
      accountId: userInfo?.accountId || '',
      role: userInfo?.role || '',
      validDiv: userInfo?.validDiv === null ? undefined : userInfo?.validDiv,
      rockTimestamp: userInfo?.rockTimestamp === null ? undefined : userInfo?.rockTimestamp,
      lastLoginTimestamp: userInfo?.lastLoginTimestamp === null ? undefined : userInfo?.lastLoginTimestamp,
    });
  };

  const createUserDetailApi = async () => {
    const http = new HttpConnection({ dispatch });
    const data = await http.post<UserDetailInfoType>(REST_API.MAINTENANCE.POST_USER_INFO_DETAIL, {
      headquartersId: loginUserInfo?.currentHeadQId,
      storeId: loginUserInfo?.currentStoreId,
      accountId: inputValue.accountId,
      password: inputValue.newPassword,
      role: inputValue.role,
      validDiv: inputValue.validDiv,
    });
    setSrcMode(SRC_MODE.EDIT);
    if (data?.accountLockTerm) accountLockTerm.current = data.accountLockTerm;
    setInputValue({
      ...inputValue,
      userId: data?.userId,
      versionNo: data?.versionNo || 0,
      accountId: data?.accountId || '',
      role: data?.role || '',
      validDiv: data?.validDiv === null ? undefined : data?.validDiv,
      rockTimestamp: data?.rockTimestamp === null ? undefined : data?.rockTimestamp,
      lastLoginTimestamp: data?.lastLoginTimestamp === null ? undefined : data?.lastLoginTimestamp,
    });
  };

  const updateUserInfoDetailApi = async () => {
    const http = new HttpConnection({ dispatch });
    await http.post<UserDetailInfoType>(REST_API.MAINTENANCE.PUT_USER_INFO_DETAIL, {
      userId: inputValue.userId,
      versionNo: inputValue.versionNo,
      headquartersId: loginUserInfo?.currentHeadQId,
      storeId: loginUserInfo?.currentStoreId,
      accountId: inputValue.accountId,
      password: inputValue.password,
      newPassword: inputValue.newPassword,
      role: inputValue.role,
      validDiv: inputValue.validDiv,
    });
  };

  const unlockAccountApi = async () => {
    const http = new HttpConnection({ dispatch });
    await http.post<UserDetailInfoType>(REST_API.MAINTENANCE.UNLOCK_ACCOUNT, {
      userId: inputValue.userId,
      versionNo: inputValue.versionNo,
    });
  };

  const deleteUserInfoApi = async () => {
    const http = new HttpConnection({ dispatch });
    await http.post<UserDetailInfoType>(REST_API.MAINTENANCE.DELETE_USER_INFO_DETAIL, {
      userId: inputValue.userId,
      versionNo: inputValue.versionNo,
      accountId: inputValue.accountId,
      role: inputValue.role,
      validDiv: inputValue.validDiv,
      headStoreId: (loginUserInfo?.currentHeadQId || '') + (loginUserInfo?.currentStoreId || ''),
    });
  };

  /* ************ Event ************ */
  // return button
  const handleClickReturn = () => {
    // isFiltered=TRUEの場合、ユーザ一覧のフィルターを元の状態に戻す。
    history.push(UrlHelper.convertQueryUrlFrontEnd(redirectLinks.MAINT_USER_LIST, { isFiltered: IS_FILTERED.TRUE }));
  };
  // register user button
  const handleClickRegist = () => {
    if (inputValue.userId === undefined) {
      // insert
      createUserDetailApi().then(() => {
        // open info dialog
        setInfoDlgMsg(infoMsgRegist);
        setOpenInfoDialog(true);
      });
      return;
    }
    // open confirm dialog
    setOpenUpdateConfirmDlg(true);
  };
  // unlock button
  const handleClickUnlock = () => {
    unlockAccountApi().then(() => {
      // open info dialog
      setInfoDlgMsg(infoMsgUnlock);
      setOpenInfoDialog(true);
      getUserDetailApi(); // 再取得
    });
  };
  // delete button
  const handleClickDelete = () => {
    setOpenDeleteConfirmDlg(true);
  };
  // 画面項目のチェンジイベント
  const handleChangeInput = (ev: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    setInputValue({
      ...inputValue,
      [ev.target.name]: ev.target.value,
    });
  };

  // confirm dialog close button
  const handleOkUpdateConfirmDlg = (): void => {
    setOpenUpdateConfirmDlg(false);
    updateUserInfoDetailApi().then(() => {
      // open info dialog
      setInfoDlgMsg(infoMsgUpdate);
      setOpenInfoDialog(true);
      getUserDetailApi(); // 再取得
    });
  };
  // confirm dialog close button
  const handleCloseUpdateConfirmDlg = (): void => {
    setOpenUpdateConfirmDlg(false);
  };
  // confirm dialog close button
  const handleOkDeleteConfirmDlg = (): void => {
    setOpenDeleteConfirmDlg(false);
    deleteUserInfoApi().then(() => {
      // info dialog
      setOpenDeleteInfoDlg(true);
    });
  };
  // confirm dialog close button
  const handleCloseDeleteConfirmDlg = (): void => {
    setOpenDeleteConfirmDlg(false);
  };
  // delete info dialog close button
  const handleCloseDeleteInfoDlg = (): void => {
    setOpenDeleteInfoDlg(false);
    // isFiltered=TRUEの場合、ユーザ一覧のフィルターを元の状態に戻す。
    history.push(UrlHelper.convertQueryUrlFrontEnd(redirectLinks.MAINT_USER_LIST, { isFiltered: IS_FILTERED.TRUE }));
  };
  // info dialog close button
  const handleCloseInfoDialog = (): void => {
    setOpenInfoDialog(false);
  };

  const lastLoginTime =
    inputValue?.lastLoginTimestamp && formatDatetime(new Date(inputValue?.lastLoginTimestamp), '/', true);
  const isAccountLock = !!inputValue?.rockTimestamp;
  let accountLock = '';
  if (isAccountLock && inputValue?.rockTimestamp) {
    const rockDate = new Date(inputValue?.rockTimestamp);
    rockDate.setMinutes(rockDate.getMinutes() + accountLockTerm.current);
    accountLock = `ロック中 ${formatTime(rockDate)}までロック`;
  }

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <div className={classes.contens}>
          <Box className="topArea">
            <ReturnButton onClick={handleClickReturn}>ユーザー一覧</ReturnButton>
            <OutlineTextBox
              id="storeId"
              label="企業"
              value={getStoreName(loginUserInfo)}
              labelPlacement="start"
              fullWidth
              disabled
              className={classes.storeName}
            />
          </Box>
          <Box className="mainArea">
            <Box className="leftArea">
              <BorderBottomBox label={`前回の\nログイン日時`} value={lastLoginTime} className={classes.lastLoginTime} />
              <BorderBottomBox label={`アカウント\nロック`} value={accountLock} className={classes.accountLock} />
              {/* 編集時 ロック解除可 */}
              {srcMode === SRC_MODE.EDIT && (
                <NomalButton onClick={handleClickUnlock} disabled={!isAccountLock} className={classes.unLockBtn}>
                  ロック解除
                </NomalButton>
              )}
            </Box>
            <Box className="rightArea">
              <OutlineTextBox
                id="accountId"
                name="accountId"
                label="ID"
                value={inputValue.accountId}
                labelPlacement="top"
                onChange={handleChangeInput}
                fullWidth
                disabled={!(srcMode === SRC_MODE.NEW)}
                className={classes.accountId}
              />

              {isEditYourSelf ? (
                <>
                  <OutlineTextBox
                    id="password"
                    name="password"
                    value={inputValue.password}
                    label="現在のパスワード"
                    labelPlacement="top"
                    type="password"
                    onChange={handleChangeInput}
                    fullWidth
                    disabled={isEditYourSelf ? false : srcMode === SRC_MODE.REFER}
                    className={classes.password}
                  />
                  <OutlineTextBox
                    id="newPassword"
                    name="newPassword"
                    value={inputValue.newPassword}
                    label="新しいパスワード"
                    labelPlacement="top"
                    type="password"
                    onChange={handleChangeInput}
                    fullWidth
                    disabled={isEditYourSelf ? false : srcMode === SRC_MODE.REFER}
                    className={classes.password}
                  />
                </>
              ) : (
                <OutlineTextBox
                  id="newPassword"
                  name="newPassword"
                  value={inputValue.newPassword}
                  label="パスワード"
                  labelPlacement="top"
                  type="password"
                  onChange={handleChangeInput}
                  fullWidth
                  disabled={isEditYourSelf ? false : srcMode === SRC_MODE.REFER}
                  className={classes.password}
                />
              )}
              <RadioButtons
                row
                name="validDiv"
                label="利用"
                radioList={validDivRadios()}
                value={inputValue.validDiv}
                onChange={handleChangeInput}
                disabled={srcMode === SRC_MODE.REFER}
                className={classes.validDiv}
              />
              <SelectBoxWithLabel
                labelPlacement="top"
                name="role"
                dataSource={
                  srcMode === SRC_MODE.REFER
                    ? getClsfcnByCategory(CLSFCN.ROLE.CATEGORY_CD).filter((cls) => cls.value === inputValue.role)
                    : roleDatasource(loginUserInfo?.role)
                }
                value={inputValue.role}
                onChange={handleChangeInput}
                label="権限"
                disabled={srcMode === SRC_MODE.REFER}
                fullWidth
                exClassName={classes.role}
              />
            </Box>
          </Box>
          <Box className="bottomArea">
            {/* 編集時 ユーザ削除可 */}
            {srcMode === SRC_MODE.EDIT && <DeleteButton onClick={handleClickDelete}>{`ユーザー\n削除`}</DeleteButton>}
            {/* 登録/編集時 ユーザ登録可 */}
            {(srcMode === SRC_MODE.NEW || srcMode === SRC_MODE.EDIT || isEditYourSelf) && (
              <DoneButton onClick={handleClickRegist} className={classes.registBtn}>
                登録
              </DoneButton>
            )}
          </Box>
        </div>
        <ConfirmDialog
          open={openUpdateConfirmDlg}
          msg={confirmMsgUpdate}
          onOk={handleOkUpdateConfirmDlg}
          onCancel={handleCloseUpdateConfirmDlg}
          addClass={{
            contentText: classes.contentText,
          }}
        />
        <ConfirmDialog
          open={openDeleteConfirmDlg}
          msg={confirmMsgDelete}
          onOk={handleOkDeleteConfirmDlg}
          onCancel={handleCloseDeleteConfirmDlg}
          addClass={{
            contentText: classes.contentText,
          }}
        />
        <InfoDialog open={openDeleteInfoDlg} msg={infoMsgDelete} closeFunc={handleCloseDeleteInfoDlg} />
        <InfoDialog open={openInfoDialog} msg={infoDlgMsg} closeFunc={handleCloseInfoDialog} />
      </Layout>
    </ScreenContext.Provider>
  );
};

export default UserDetail;
