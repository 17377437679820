import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { REST_API } from 'constants/apiUrls';
import {
  AppInfoType,
  ClassificationListType,
  CommonStateType,
  DeviceInfoType,
  StoreInfoType,
  UserInfoType,
} from 'types/common/commonType';
import HttpConnection from 'utils/httpConnection';
import UrlHelper from 'utils/url.helper';
import CLSFCN from 'constants/classification';

/* ************ State ************ */
// Reduxで管理するグローバルステートの設定
const initialState: CommonStateType = {
  loading: false,
  loadingQueue: 0,
  errorInfo: {
    accessDenyError: false,
    isError: false,
    isSystemError: false,
    isUnauthorizedError: true,
    status: 0,
    logId: '',
    errorDate: '',
    errorNo: '',
    errorCode: '',
    message: '',
    errors: undefined,
  },
  classificationList: {},
  menuInfo: null,
  userInfo: null,
  appInfo: { versionNo: '', detailInfo: '', isValidInfo: false, isShowAlertVersion: false },
  deviceInfo: { isMobile: false, isPc: true, deviceType: 'pc' },
  suggestions: [],
};

/* ******* Event ****** */
// サジェスト用店舗リスト取得
export const getStoreListForSuggestThunk = createAsyncThunk(
  'common/getStoreList',
  async (payload: { searchString: string }, thunk) => {
    const http = new HttpConnection(thunk, false);
    let data = [];
    if (payload.searchString) {
      data = await http.get<Array<StoreInfoType>>(UrlHelper.convertQueryUrl(REST_API.COMMON.GET_STORE_LIST, payload));
    } else {
      data = await http.get<Array<StoreInfoType>>(REST_API.COMMON.GET_STORE_LIST_INITIAL);
    }
    return data;
  },
);
// セキュア情報取得
export const getUserAuthThunk = createAsyncThunk('common/getUserAuth', async (payload, thunk) => {
  const http = new HttpConnection(thunk, false);
  const data = await http.get<{
    role: string;
    enableScreenIdList: Array<string>;
  }>(REST_API.COMMON.GET_USER_AUTH);
  return data;
});

const slice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
      state.loadingQueue += 1;
    },
    hideLoading: (state) => {
      // Ensure that the loadingQueue is not negative
      if (state.loadingQueue > 0) {
        state.loadingQueue -= 1;
      }
      state.loading = state.loadingQueue > 0;
    },
    handleCallApiSuccess: (state) => {
      state.errorInfo.isError = false;
    },
    handleCallApiError: (state, { payload }) => {
      state.errorInfo.isError = true;
      state.errorInfo.errors = ![602].includes(payload.status) ? payload.data?.errors ?? undefined : undefined;
      state.errorInfo.isSystemError = ![401, 403, 404, 419, 422, 503].includes(payload.status);
      state.errorInfo.isUnauthorizedError = [401, 403, 419].includes(payload.status);
      state.errorInfo.status = payload.status;
      state.errorInfo.errorDate = payload.data?.date ?? '';
      state.errorInfo.errorNo = payload.data?.logId ?? '';
      state.errorInfo.logId = payload.data?.logId ?? '';
      state.errorInfo.errorCode = payload.data?.errorCode ?? '';
      state.errorInfo.message = payload.data?.message ?? payload.message;
    },
    handleCallFrontError: (state, action: PayloadAction<{ errors: { key: string[] } }>) => {
      state.errorInfo.isError = true;
      state.errorInfo.errors = action.payload.errors;
    },
    handleCallSystemError: (state, { payload }) => {
      state.errorInfo.isSystemError = true;
      state.errorInfo.errorNo = payload.errorNo;
    },
    handleClearApiError: (state) => {
      state.errorInfo.isError = false;
      state.errorInfo.errors = undefined;
      state.errorInfo.errorDate = '';
      state.errorInfo.errorNo = '';
    },
    handleClearSystemError: (state) => {
      state.errorInfo.isError = false;
      state.errorInfo.errors = undefined;
      state.errorInfo.isSystemError = false;
      state.errorInfo.isUnauthorizedError = false;
      state.errorInfo.errorDate = '';
      state.errorInfo.errorNo = '';
      state.errorInfo.logId = '';
      state.errorInfo.errorCode = '';
      state.errorInfo.message = '';
    },
    setUserInfo: (state, action: PayloadAction<UserInfoType>) => {
      state.userInfo = action.payload;
    },
    setAppInfo(state, action: PayloadAction<AppInfoType>) {
      state.appInfo = action.payload;
    },
    setAppVersion(state, action: PayloadAction<string>) {
      if (!state.appInfo.isShowAlertVersion) {
        const oldVersion = localStorage.getItem('versionNo');
        const isShow =
          (oldVersion !== null && oldVersion !== action.payload) ||
          (state.appInfo.versionNo !== '' && state.appInfo.versionNo !== action.payload);
        state.appInfo = {
          ...state.appInfo,
          isShowAlertVersion: isShow,
        };
        localStorage.setItem('versionNo', action.payload);
      }
    },
    clearShowDialogCheckVersion(state) {
      const newVersion = localStorage.getItem('versionNo') ?? '';
      state.appInfo = { ...state.appInfo, versionNo: newVersion, isShowAlertVersion: false };
    },
    setDeviceInfo: (state, action: PayloadAction<DeviceInfoType>) => {
      state.deviceInfo = action.payload;
    },
    setAuth: (state) => {
      state.errorInfo.isUnauthorizedError = false;
    },
    setClassificationList: (state, action: PayloadAction<ClassificationListType>) => {
      // 不要だけどいったん定義する。
      state.classificationList = action.payload;
    },
    setStoreInfo: (state, action: PayloadAction<StoreInfoType>) => {
      const { userInfo } = state;
      const { currentHeadQId, currentHeadQName, currentStoreId, currentStoreName } = action.payload;
      if (userInfo) {
        // システム管理者のみ本部id、本部名を設定する。
        if (userInfo.role === CLSFCN.ROLE.SYSTEM_ADMIN) {
          userInfo.currentHeadQId = currentHeadQId;
          userInfo.currentHeadQName = currentHeadQName;
        }
        userInfo.currentStoreId = currentStoreId;
        userInfo.currentStoreName = currentStoreName;
      }
    },
    initializeSuggestions: (state) => {
      state.suggestions = initialState.suggestions;
    },
    initializeCommonSlice: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getStoreListForSuggestThunk.fulfilled, (state, action) => {
      state.suggestions = action.payload;
    });
    builder.addCase(getUserAuthThunk.fulfilled, (state, action) => {
      const { userInfo } = state;
      if (userInfo) {
        userInfo.enableScreenIdList = action.payload.enableScreenIdList;
        userInfo.role = action.payload.role;
      }
    });
  },
});

const { reducer } = slice;

export const {
  showLoading,
  hideLoading,
  handleCallApiSuccess,
  handleCallApiError,
  handleCallSystemError,
  handleClearApiError,
  handleClearSystemError,
  handleCallFrontError,
  setClassificationList,
  setUserInfo,
  setAppInfo,
  setAppVersion,
  clearShowDialogCheckVersion,
  setDeviceInfo,
  setAuth,
  setStoreInfo,
  initializeSuggestions,
  initializeCommonSlice,
} = slice.actions;

export default reducer;
