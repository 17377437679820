import { makeStyles, Table, TableBody, TableCell, TableContainer, TableRow, Theme } from '@material-ui/core';
import React, { useCallback, useEffect, useRef } from 'react';
import NormalButton from 'components/system/atoms/buttons/NormalButton';
import ListCheckBox from 'components/system/atoms/checkBoxs/ListCheckBox';
import COMMON from 'constants/common';
import TooltipComponent from 'components/system/organisms/dialogs/TooltipComponent';
import { getStationName, renderMachineType } from 'utils/common.helper';
import MachineStationTableHead from 'components/data/organisms/tables/common/MachineStationTableHead';
import { MachineListType, MachineTableRowType, MachineTableType } from 'types/voice/voiceType';
import CheckedIconBlur from 'assets/icons/CheckedIconBlur';
import { MachineSelectStationNameType } from 'types/group/GroupType';

/* ************ Style ************ */
const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    height: '391px',
    width: '1100px',
  },
  table: {
    tableLayout: 'fixed',
    borderCollapse: 'separate',
    '& th,td': {
      border: '1px solid',
      borderRightColor: theme.base.tableBorder,
      borderLeftColor: theme.base.tableBorder,
      borderTopColor: theme.base.tableBorder,
      borderBottomColor: theme.base.tableBorder,
      padding: 6,
    },
    '& td.noneBorderTop': {
      borderTopColor: '#FFFFFF !important',
    },
    '& td.noneBorderBottom': {
      borderBottomColor: '#FFFFFF !important',
    },
    '& th': {
      textAlign: 'center',
      fontSize: '23px',
      lineHeight: '28px',
      padding: 0,
    },
    '& th:last-child': {
      textAlign: 'left',
      paddingLeft: '176px',
      // レイアウトに合わせるため、1行目を字下げして中央揃えの見た目にする
      textIndent: '-2em',
    },
    '& tbody tr': {
      height: '58px',
    },
    '& tbody tr:nth-child(odd)': {
      backgroundColor: theme.base.tableRowPrimary,
    },
    '& tbody tr:nth-child(even)': {},
    '& td': {
      fontSize: '15px',
      lineHeight: '23px',
      '& .cellStyle': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'fade',
      },
    },
  },
  tdSelectStation: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '58px',
    paddingRight: 10,
  },
  gifNameContent: {
    display: 'flex',
  },
  tdStation: {
    textAlign: 'left',
    padding: '0 8px 18px 0',
  },
  confDetail: {
    backgroundColor: '#B8FFC9',
    borderColor: '#B8FFC9',
    color: theme.palette.common.black,
    height: '40px',
    width: '120px',
    boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.5)',
    '&:hover': {
      backgroundColor: '#B8FFC9',
      boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.5)',
    },
  },
  '&:hover': {
    backgroundColor: '#B8FFC9',
    boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.5)',
  },
  stationSelect: {
    width: '56px',
  },
  station: {
    flexDirection: 'column',
    width: '430px',
    textAlign: 'left',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    margin: 'auto',
  },
  receiveWaitingWrap: {
    width: '120px',
  },
  confDetailWrap: {
    width: '120px',
    marginLeft: '8px',
  },
  giftNameWrap: {
    paddingTop: '5px',
    flex: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    'word-break': 'break-all',
  },
  boardSerialStyle: {
    textAlign: 'center',
  },
}));

/* ************ Component ************ */

/* ******** Control Component ******** */
const VoiceStationTableRow: React.FC<MachineTableRowType> = React.memo(
  ({ row, index, onHandleCheck, selectedVoiceId, handleCancelWaitingAuth }) => {
    const classes = useStyles();
    const getStationNameFunc = React.useCallback(
      ({ machineType, stationName, leftRight, giftName }: MachineSelectStationNameType): string =>
        `${getStationName(machineType, stationName, leftRight)} ${giftName ?? COMMON.BLANK}`,
      [],
    );
    /**
     * Render button
     */
    const renderButton = useCallback(() => {
      if (
        row.voiceId == null ||
        (row.voiceId === selectedVoiceId && (row.voiceReceive == null || row.voiceReceive === '2'))
      ) {
        return <></>;
      }
      if (row.voiceId === selectedVoiceId && row.voiceReceive === '1') {
        return (
          <NormalButton className={classes.confDetail} onClick={() => handleCancelWaitingAuth(row)}>
            受取待機中
          </NormalButton>
        );
      }
      if (row.voiceId !== selectedVoiceId && (row.voiceReceive === '1' || row.voiceReceive === '2')) {
        return (
          <>
            別ボイス
            <br />
            使用中
          </>
        );
      }
      return <></>;
    }, [row]);

    return (
      <>
        <TableRow key={index}>
          <TableCell>
            <div className={classes.boardSerialStyle}>
              <div className="cellStyle">{row.boardSerial}</div>
              <div className="cellStyle">{`(Ver. ${row.machineVersion ?? ''})`}</div>
            </div>
          </TableCell>
          <TableCell>
            <div className="cellStyle">{renderMachineType(row.machineType)}</div>
          </TableCell>
          <TableCell className={`${classes.tdStation}`}>
            <div className={classes.tdSelectStation}>
              <div className={classes.gifNameContent}>
                <div className={classes.stationSelect}>
                  <ListCheckBox
                    checked={row.checked}
                    name="selectStation"
                    onChange={() => onHandleCheck(row)}
                    icon={row.isSelected ? <CheckedIconBlur /> : undefined}
                    disabled={
                      (row.voiceId !== selectedVoiceId &&
                        row.voiceId !== null &&
                        (row.voiceReceive === '1' || row.voiceReceive === '2')) ||
                      (row.voiceId === selectedVoiceId && (row.voiceReceive === '1' || row.voiceReceive === null))
                    }
                  />
                </div>
                <div className={classes.station}>
                  {row.machineType === COMMON.MACHINE_TYPE.CLENA3 ? (
                    <>
                      <TooltipComponent
                        tooltipText={row.leftGiftName}
                        displayWord={`${getStationNameFunc({
                          machineType: row.machineType,
                          stationName: row.leftStationName,
                          leftRight: COMMON.LEFT_RIGHT.LEFT,
                          giftName: row.leftGiftName,
                        })}`}
                      />
                      <TooltipComponent
                        tooltipText={row.rightGiftName}
                        displayWord={`${getStationNameFunc({
                          machineType: row.machineType,
                          stationName: row.rightStationName,
                          leftRight: COMMON.LEFT_RIGHT.RIGHT,
                          giftName: row.rightGiftName,
                        })}`}
                      />
                    </>
                  ) : (
                    <TooltipComponent
                      tooltipText={row.leftGiftName}
                      displayWord={`${getStationNameFunc({
                        machineType: row.machineType,
                        stationName: row.leftStationName,
                        leftRight: COMMON.LEFT_RIGHT.LEFT,
                        giftName: row.leftGiftName,
                      })}`}
                    />
                  )}
                </div>
              </div>
              <div className={classes.confDetailWrap}>{renderButton()}</div>
            </div>
          </TableCell>
        </TableRow>
      </>
    );
  },
);

/* ******** Main Component ******** */
/**
 *
 * @param machineList
 * @param tableId
 * @param selectVoiceId
 * @param updateMachineListSubmit
 * @param handleCancelWaitingAuth
 * @constructor
 */
const VoiceStationTable: React.FC<MachineTableType> = ({
  machineList,
  tableId,
  selectVoiceId,
  handleShowNextButton,
  handleCancelWaitingAuth,
  handleChangeSelectStation,
}) => {
  const classes = useStyles();
  const divBodyRef = useRef<HTMLTableSectionElement | null>(null);
  const bodyHeight = 333;

  const [machineDataList, setMachineDataList] = React.useState<MachineListType[]>(machineList);
  useEffect(() => {
    setMachineDataList(machineList);
  }, [machineList]);

  useEffect(() => {
    const checkShowButton = () => {
      if (divBodyRef.current) {
        const { scrollHeight } = divBodyRef.current;
        handleShowNextButton(scrollHeight > bodyHeight);
      }
    };

    // init
    checkShowButton();
    // Add resize listener
    window.addEventListener('resize', checkShowButton);

    return () => {
      window.removeEventListener('resize', checkShowButton);
    };
  }, [machineDataList]);

  const headerColumns = [
    { id: 'boardSerial', title: '登録番号', width: 143, height: 58 },
    { id: 'machineType', title: '機種名', width: 141, height: 58 },
    { id: 'station', title: `ステーション名\u00A0\u00A0\u00A0景品名`, width: '100%', height: 58 },
  ];

  return (
    <>
      <TableContainer id={tableId} className={classes.container}>
        <Table className={classes.table} stickyHeader aria-label="sticky table">
          <MachineStationTableHead columns={headerColumns} />
          <TableBody ref={divBodyRef}>
            {machineDataList.map((row, index) => (
              <VoiceStationTableRow
                key={`key_${String(index)}_${row.boardSerial}`}
                row={row}
                index={index}
                selectedVoiceId={selectVoiceId}
                onHandleCheck={handleChangeSelectStation}
                handleCancelWaitingAuth={handleCancelWaitingAuth}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default VoiceStationTable;
