import { Checkbox, CheckboxProps, makeStyles } from '@material-ui/core';
import React, { FC, memo } from 'react';
import CheckedIcon from 'assets/icons/CheckedIcon';
import UnCheckedIcon from 'assets/icons/UnCheckedIcon';
import { themeColor } from 'constants/theme';

const useStyles = makeStyles({
  root: {
    // disableがtrueのときはチェックボックスの塗りを変更する
    '& input:disabled': {
      '&+svg #GroupSelectBox': {
        fill: themeColor.disabled,
      },
    },
    // ホバー時の効果を無効にしたい場合は以下の内容を設定。
    // '&:hover': {
    //   backgroundColor: 'transparent',
    // },
  },
});

// ホバー時の効果を無効にしたい場合は以下の内容を設定。
// checkboxコンポーネントに設定
//  className={classes.root}
//  disableRipple

const ListCheckBox: FC<CheckboxProps> = ({ name, disabled, checked, onChange, icon }) => {
  const classes = useStyles();
  const unCheckIcon = icon ?? <UnCheckedIcon />;
  return (
    <Checkbox
      checked={checked}
      checkedIcon={<CheckedIcon />}
      color="default"
      defaultChecked={false}
      disabled={disabled}
      icon={unCheckIcon}
      name={name}
      onChange={onChange}
      className={classes.root}
    />
  );
};

// レンダーが前回と変わらない場合前回のレンダーを再利用
export default memo(ListCheckBox);
